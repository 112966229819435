import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import { authService } from "../../services/authService";
import { moneyService } from "../../services/moneyService";
import { removeAuthorizedUser } from "../../reducers";

const NavBar = ({ authorizedUser, removeAuthorizedUser }) => {
  const removeCookiesAndUserFromState = () => {
    authService.removeCookies();
    removeAuthorizedUser();
  };

  const isAdminOrSuper = () => authorizedUser.role === "ADMIN" || authorizedUser.super;
  const isAdmin = () => authorizedUser.role === "ADMIN";

  const checkActiveCharts = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    return pathname === "/charts/proportional" || pathname === "/charts/comparative";
  };

  const checkActiveAdminPanel = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    return (
      pathname === "/admin-panel/users" || pathname === "/admin-panel/departments" || pathname === "/admin-panel/tags"
    );
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top" className="pl-3 pr-3">
      <Navbar.Brand as={NavLink} to="/">
        Expenses tracker
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {authorizedUser ? (
          <>
            {isAdminOrSuper() ? (
              <Nav>
                <Nav.Link to="/" exact as={NavLink}>
                  Home
                </Nav.Link>
                <Nav.Link to="/charts" as={NavLink} isActive={checkActiveCharts}>
                  Charts
                </Nav.Link>
                {isAdmin() ? (
                  <Nav.Link to="/admin-panel" as={NavLink} isActive={checkActiveAdminPanel}>
                    Administration
                  </Nav.Link>
                ) : null}
              </Nav>
            ) : null}
            <Nav className="ml-auto">
              <Navbar.Text className="mr-3">
                Login as: <span className="text-white">{authorizedUser.name}</span>
              </Navbar.Text>
              {authorizedUser.role === "MANAGER" ? (
                <Navbar.Text className="mr-5">
                  Balance:
                  <span className="text-white">
                    {" " +
                      moneyService.toFloatStringWithSpaces(authorizedUser.balance) +
                      " " +
                      (authorizedUser.currency === "UAH" ? "₴" : "$")}
                  </span>
                </Navbar.Text>
              ) : null}
              <Nav.Link onClick={removeCookiesAndUserFromState}>Logout</Nav.Link>
            </Nav>
          </>
        ) : (
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to="/login">
              Login
            </Nav.Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = state => {
  return {
    authorizedUser: state.authorizedUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeAuthorizedUser: () => dispatch(removeAuthorizedUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
