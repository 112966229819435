import React, { useState } from "react";
import { Button, ButtonToolbar, Card } from "react-bootstrap";
import TagEditor from "../TagEditor/TagEditor";
import DeleteConfirmation from "../../../DeleteConfirmation/DeleteConfirmation";

import { tagService } from "../../../../services/tagService";

const Tag = ({ tag, onChange, onError }) => {
  const [tagEditorShow, setTagEditorShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

  const deleteTag = tagId => {
    setDeleteConfirmationShow(false);
    tagService
      .deleteTag(tagId)
      .then(onChange)
      .catch(onError);
  };

  return (
    <Card className="tag">
      <Card.Body className="d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between align-items-baseline">
          <Card.Text className="tag-name rounded p-1 mr-1">
            <span className="font-weight-bold p-0">{tag.name}</span>
          </Card.Text>
          <ButtonToolbar className="d-flex flex-nowrap">
            <Button variant="light" onClick={() => setTagEditorShow(true)}>
              <i className="fa fa-pencil-square-o" aria-hidden="true" />
            </Button>
            <Button variant="light" onClick={() => setDeleteConfirmationShow(true)}>
              <i className="fa fa-trash-o" aria-hidden="true" />
            </Button>
            {tagEditorShow ? (
              <TagEditor show={tagEditorShow} onHide={() => setTagEditorShow(false)} data={tag} onAccept={onChange} />
            ) : null}
            {deleteConfirmationShow ? (
              <DeleteConfirmation
                show={deleteConfirmationShow}
                onHide={() => setDeleteConfirmationShow(false)}
                onDelete={() => deleteTag(tag._id)}
              />
            ) : null}
          </ButtonToolbar>
        </div>

        <Card.Text>
          <small className="text-muted">Created at {new Date(tag.createdAt).toLocaleDateString()}</small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Tag;
