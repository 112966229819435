import React from "react";
import { Modal, Button } from "react-bootstrap";

const TransferConfirmation = ({ show, onHide, onTransfer, category }) => {
  return (
    <div>
      <div className="TransferConfirmation">
        <Modal
          show={show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Confirm transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please confirm the expenses transfer to category <span className="text-info">{category}</span>. After
              confirmation, the data <strong>cannot be returned.</strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="info" onClick={onTransfer}>
              Transfer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TransferConfirmation;
