import React, { useState, useEffect } from "react";
import * as QueryString from "query-string";
import { NavLink } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import AlertMessage from "../../AlertMessage/AlertMessage";
import ComparativeChartFilters from "./ComparativeChartFilters/ComparativeChartFilters";
import "./ComparativeChart.scss";

import { chartService } from "../../../services/chartService";
import { moneyService } from "../../../services/moneyService";
import { localeService } from "../../../services/localeService";

const ComparativeChart = ({ authorizedUser, getMe }) => {
  const [state, setState] = useState({ minStartDate: new Date("1996-09-02"), tags: [] });

  const [filters, setFilters] = useState(null);
  const onFiltersChange = data => setFilters(data);

  const getColor = () => {
    if (filters) {
      if (filters.department) return "#04761f";
      else if (filters.category) return "#01579b";
      else if (filters.tag) return "#4a148c";
    }
    return "#b71c1c";
  };

  const [color, setColor] = useState(getColor());
  const [nameClass, setNameClass] = useState(filters && filters.tag ? "selected-name tag-name" : "selected-name");

  const [errorMessage, setErrorMessage] = useState("");

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const requestComparativeStats = filters => {
    chartService
      .getComparativeStats(filters)
      .then(({ data }) => {
        setState({
          name: data.name ? data.name : null,
          stats: data.stats,
          minStartDate: !isNaN(Date.parse(data.minDate))
            ? new Date(new Date(data.minDate).setDate(1))
            : new Date("1996-09-02"),
          tags: data.tags
        });
      })
      .then(getMe)
      .catch(onError);
  };

  useEffect(() => {
    if (filters) requestComparativeStats(filters);
  }, [filters]);

  useEffect(() => {
    setColor(getColor());
    setNameClass(filters && filters.tag ? "selected-name tag-name" : "selected-name");
  }, [state]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="chart-tooltip">
          <p className="mb-1">{label}</p>
          <p style={{ color: payload[0].fill }}>{moneyService.toFloatStringWithSpaces(payload[0].value)}</p>
        </div>
      );
    }
    return null;
  };

  const CustomizedYTick = ({ x, y, width, payload }) => {
    return (
      <text className="small" fill="#6c757d" x={x} y={y} width={width} textAnchor="end">
        {moneyService.toFloatStringWithSpaces(payload.value)}
      </text>
    );
  };

  const CustomizedXTick = ({ x, y, width, textAnchor, payload }) => {
    return (
      <text className="small" fill="#6c757d" x={x} y={y} dy={10} width={width} textAnchor={textAnchor}>
        {payload.value}
      </text>
    );
  };

  const getStatLocaleName = stat => {
    return new Date(stat.year, stat.month - 1, 1).toLocaleDateString(navigator.language, {
      year: "numeric",
      month: "numeric"
    });
  };

  const mapStatsToLocaleName = stats => {
    return stats.map(stat => ({
      name: getStatLocaleName(stat),
      money: stat.money
    }));
  };

  return (
    <>
      <AlertMessage message={errorMessage} type="error" />
      <div className="ComparativeChart">
        <ComparativeChartFilters
          authorizedUser={authorizedUser}
          tags={state.tags}
          minStartDate={state.minStartDate}
          onFiltersChange={onFiltersChange}
        />
        {state.stats ? (
          <div className="main-container">
            {state.stats.reduce((acc, stat) => acc + +stat.money, 0) ? (
              <div className="chart-container">
                {state.name ? (
                  <div className={nameClass}>
                    {state.name.length > 1 ? <span className="text-muted">{state.name[1]}</span> : null}
                    <span>{state.name[0]}</span>
                  </div>
                ) : null}
                <span className="sum-money" style={{ color }}>
                  {moneyService.toFloatStringWithSpaces(state.stats.reduce((acc, stat) => acc + +stat.money, 0)) + " $"}
                </span>
                <BarChart
                  width={600}
                  height={400}
                  data={mapStatsToLocaleName(state.stats)}
                  margin={{
                    top: 5,
                    right: 35,
                    left: 15,
                    bottom: 5
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    tickLine={false}
                    dataKey="name"
                    padding={{ left: 20, right: 20 }}
                    interval="preserveStartEnd"
                    tick={<CustomizedXTick />}
                  />
                  <YAxis tickLine={false} axisLine={false} tick={<CustomizedYTick />} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="money" barSize={20} fill={color} />
                </BarChart>
                <ListGroup variant="flush" className="chart-legend">
                  {state.stats.map((stat, i) => {
                    const start = localeService.dateToString(new Date(stat.year, stat.month - 1, 1));
                    const end = localeService.dateToString(
                      new Date(stat.year, stat.month, 0).getTime() <= new Date().getTime()
                        ? new Date(stat.year, stat.month, 0)
                        : new Date()
                    );
                    const departmentByCategory = filters.category
                      ? authorizedUser.departments.find(dep => dep.categories.find(cat => cat._id === filters.category))
                      : null;

                    const queryObject = {
                      start,
                      end,
                      type: "expense",
                      showUsers: true,
                      ...(filters.department && { department: filters.department }),
                      ...(filters.category && { categories: filters.category, department: departmentByCategory._id }),
                      ...(filters.tag && { tag: filters.tag })
                    };
                    return (
                      <ListGroup.Item
                        as={NavLink}
                        key={stat.year + stat.month + "_chart_" + i}
                        className="selected-legend"
                        to={`/?${QueryString.stringify(queryObject)}`}
                        target="_blank"
                      >
                        <div>
                          <span>{getStatLocaleName(stat)}</span>
                        </div>
                        <div className="numbers">
                          <span>{moneyService.toFloatStringWithSpaces(stat.money)}</span>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            ) : (
              <div className="text-muted text-center">Nothing to compare</div>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ComparativeChart;
