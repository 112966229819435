import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import NavBar from "./components/NavBar/NavBar";
import PrivateRoute from "./components/Route/PrivateRoute";
import Login from "./components/Login/Login";
import Expenses from "./components/Expenses/Expenses";
import Charts from "./components/Charts/Charts";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import NotFound from "./components/NotFound/NotFound";

import { isLoggedInSelector, isAdminSelector, isAdminOrSuperSelector } from "./reducers";

const App = ({ hasAuthBeenChecked, isLoggedIn, isAdmin, isAdminOrSuper }) => {
  return hasAuthBeenChecked ? (
    <div>
      <NavBar />
      <Switch>
        <Route exact path="/login" component={Login} />

        <PrivateRoute exact path="/" component={Expenses} hasAccess={isLoggedIn} />

        <Redirect exact from="/charts" to="/charts/proportional" />
        <PrivateRoute
          exact
          path={["/charts/proportional", "/charts/comparative"]}
          component={Charts}
          hasAccess={isAdminOrSuper}
        />

        <Redirect exact from="/admin-panel" to="/admin-panel/users" />
        <PrivateRoute
          exact
          path={["/admin-panel/users", "/admin-panel/departments", "/admin-panel/tags"]}
          component={AdminPanel}
          hasAccess={isAdmin}
        />

        <Route component={NotFound} />
      </Switch>
    </div>
  ) : null;
};

const mapStateToProps = state => {
  return {
    hasAuthBeenChecked: state.hasAuthBeenChecked,
    isLoggedIn: isLoggedInSelector(state),
    isAdmin: isAdminSelector(state),
    isAdminOrSuper: isAdminOrSuperSelector(state)
  };
};

export default connect(mapStateToProps)(App);
