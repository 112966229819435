import React from "react";
import Select from "react-select";
import "./Pages.scss";

const Pages = ({ currentPage, pageLimit, records, totalPages, onCurrentPageChange, onPageLimitChange }) => {
  const pageNeighbours = 1;

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = ["DOTS", ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, "DOTS"];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = ["DOTS", ...pages, "DOTS"];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();

  return (
    <div className="Pages d-flex justify-content-center">
      {totalPages === 1 ? null : (
        <nav aria-label="Page navigation">
          <ul className="pagination">
            {pages.map((page, index) => {
              if (page === "DOTS") {
                return (
                  <li key={index} className="page-item disabled">
                    <span className="page-link">
                      <span aria-hidden="true">...</span>
                    </span>
                  </li>
                );
              } else {
                return (
                  <li key={index} className={`page-item${currentPage === page ? " active" : ""}`}>
                    <span className="page-link" onClick={() => onCurrentPageChange(page)}>
                      {page}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      )}

      {records ? (
        <div className="page-limit">
          <Select
            className="ml-2 page-limit-container"
            menuPlacement="top"
            isSearchable={false}
            value={{ value: pageLimit, label: pageLimit }}
            onChange={opt => onPageLimitChange(opt.value)}
            options={[
              { value: 20, label: "20" },
              { value: 50, label: "50" },
              { value: 100, label: "100" }
            ]}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Pages;
