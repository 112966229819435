import axios from "axios";
import { createSlice, combineReducers } from "@reduxjs/toolkit";
import config from "../config";

// Initial States
const initialStateHasAuthBeenChecked = false;
const initialStateAuthorizedUser = null;

// Slices
const hasAuthBeenCheckedSlice = createSlice({
  name: "hasAuthBeenChecked",
  initialState: initialStateHasAuthBeenChecked,
  reducers: {
    setHasAuthBeenCheckedToTrue() {
      return true;
    }
  }
});

const authorizedUserSlice = createSlice({
  name: "authorizedUser",
  initialState: initialStateAuthorizedUser,
  reducers: {
    setAuthorizedUser(state, action) {
      return action.payload;
    },
    removeAuthorizedUser() {
      return initialStateAuthorizedUser;
    }
  }
});

// Actions
export const { setHasAuthBeenCheckedToTrue } = hasAuthBeenCheckedSlice.actions;
export const { setAuthorizedUser, removeAuthorizedUser } = authorizedUserSlice.actions;

// Selectors
export const authorizedUserSelector = state => state.authorizedUser;
export const isLoggedInSelector = state => !!state.authorizedUser;
export const isAdminSelector = state => state.authorizedUser && state.authorizedUser.role === "ADMIN";
export const isAdminOrSuperSelector = state => {
  return state.authorizedUser && (state.authorizedUser.role === "ADMIN" || state.authorizedUser.super);
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getMe = () => dispatch => {
  return axios.get(config.apiUrl + "/api/auth/get_me/").then(res => dispatch(setAuthorizedUser(res.data)));
};

// Root Reducer
export default combineReducers({
  hasAuthBeenChecked: hasAuthBeenCheckedSlice.reducer,
  authorizedUser: authorizedUserSlice.reducer
});
