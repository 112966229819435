import React from "react";
import { Modal, Button } from "react-bootstrap";

const RemoveTagsConfirmation = ({ show, onHide, onRemove }) => {
  return (
    <div>
      <div className="RemoveTagsConfirmation">
        <Modal
          show={show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Confirm tags removing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please confirm the removing. After confirmation, <strong>all tags will be removed</strong> from the
              selected expenses.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onRemove}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default RemoveTagsConfirmation;
