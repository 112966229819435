import enGB from "date-fns/locale/en-GB";

const getLocaleDateString = () => {
  const formats = {
    "ar-SA": "dd/MM/yy",
    "bg-BG": "dd.M.yyyy",
    "ca-ES": "dd/MM/yyyy",
    "zh-TW": "yyyy/M/d",
    "cs-CZ": "d.M.yyyy",
    "da-DK": "dd-MM-yyyy",
    "de-DE": "dd.MM.yyyy",
    "el-GR": "d/M/yyyy",
    "en-US": "M/d/yyyy",
    "fi-FI": "d.M.yyyy",
    "fr-FR": "dd/MM/yyyy",
    "he-IL": "dd/MM/yyyy",
    "hu-HU": "yyyy. MM. dd.",
    "is-IS": "d.M.yyyy",
    "it-IT": "dd/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "ko-KR": "yyyy-MM-dd",
    "nl-NL": "d-M-yyyy",
    "nb-NO": "dd.MM.yyyy",
    "pl-PL": "yyyy-MM-dd",
    "pt-BR": "d/M/yyyy",
    "ro-RO": "dd.MM.yyyy",
    "ru-RU": "dd.MM.yyyy",
    "hr-HR": "d.M.yyyy",
    "sk-SK": "d. M. yyyy",
    "sq-AL": "yyyy-MM-dd",
    "sv-SE": "yyyy-MM-dd",
    "th-TH": "d/M/yyyy",
    "tr-TR": "dd.MM.yyyy",
    "ur-PK": "dd/MM/yyyy",
    "id-ID": "dd/MM/yyyy",
    "uk-UA": "dd.MM.yyyy",
    "be-BY": "dd.MM.yyyy",
    "sl-SI": "d.M.yyyy",
    "et-EE": "d.MM.yyyy",
    "lv-LV": "yyyy.MM.dd.",
    "lt-LT": "yyyy.MM.dd",
    "fa-IR": "MM/dd/yyyy",
    "vi-VN": "dd/MM/yyyy",
    "hy-AM": "dd.MM.yyyy",
    "az-Latn-AZ": "dd.MM.yyyy",
    "eu-ES": "yyyy/MM/dd",
    "mk-MK": "dd.MM.yyyy",
    "af-ZA": "yyyy/MM/dd",
    "ka-GE": "dd.MM.yyyy",
    "fo-FO": "dd-MM-yyyy",
    "hi-IN": "dd-MM-yyyy",
    "ms-MY": "dd/MM/yyyy",
    "kk-KZ": "dd.MM.yyyy",
    "ky-KG": "dd.MM.yy",
    "sw-KE": "M/d/yyyy",
    "uz-Latn-UZ": "dd/MM yyyy",
    "tt-RU": "dd.MM.yyyy",
    "pa-IN": "dd-MM-yy",
    "gu-IN": "dd-MM-yy",
    "ta-IN": "dd-MM-yyyy",
    "te-IN": "dd-MM-yy",
    "kn-IN": "dd-MM-yy",
    "mr-IN": "dd-MM-yyyy",
    "sa-IN": "dd-MM-yyyy",
    "mn-MN": "yy.MM.dd",
    "gl-ES": "dd/MM/yy",
    "kok-IN": "dd-MM-yyyy",
    "syr-SY": "dd/MM/yyyy",
    "dv-MV": "dd/MM/yy",
    "ar-IQ": "dd/MM/yyyy",
    "zh-CN": "yyyy/M/d",
    "de-CH": "dd.MM.yyyy",
    "en-GB": "dd/MM/yyyy",
    "es-MX": "dd/MM/yyyy",
    "fr-BE": "d/MM/yyyy",
    "it-CH": "dd.MM.yyyy",
    "nl-BE": "d/MM/yyyy",
    "nn-NO": "dd.MM.yyyy",
    "pt-PT": "dd-MM-yyyy",
    "sr-Latn-CS": "d.M.yyyy",
    "sv-FI": "d.M.yyyy",
    "az-Cyrl-AZ": "dd.MM.yyyy",
    "ms-BN": "dd/MM/yyyy",
    "uz-Cyrl-UZ": "dd.MM.yyyy",
    "ar-EG": "dd/MM/yyyy",
    "zh-HK": "d/M/yyyy",
    "de-AT": "dd.MM.yyyy",
    "en-AU": "d/MM/yyyy",
    "es-ES": "dd/MM/yyyy",
    "fr-CA": "yyyy-MM-dd",
    "sr-Cyrl-CS": "d.M.yyyy",
    "ar-LY": "dd/MM/yyyy",
    "zh-SG": "d/M/yyyy",
    "de-LU": "dd.MM.yyyy",
    "en-CA": "dd/MM/yyyy",
    "es-GT": "dd/MM/yyyy",
    "fr-CH": "dd.MM.yyyy",
    "ar-DZ": "dd-MM-yyyy",
    "zh-MO": "d/M/yyyy",
    "de-LI": "dd.MM.yyyy",
    "en-NZ": "d/MM/yyyy",
    "es-CR": "dd/MM/yyyy",
    "fr-LU": "dd/MM/yyyy",
    "ar-MA": "dd-MM-yyyy",
    "en-IE": "dd/MM/yyyy",
    "es-PA": "MM/dd/yyyy",
    "fr-MC": "dd/MM/yyyy",
    "ar-TN": "dd-MM-yyyy",
    "en-ZA": "yyyy/MM/dd",
    "es-DO": "dd/MM/yyyy",
    "ar-OM": "dd/MM/yyyy",
    "en-JM": "dd/MM/yyyy",
    "es-VE": "dd/MM/yyyy",
    "ar-YE": "dd/MM/yyyy",
    "en-029": "MM/dd/yyyy",
    "es-CO": "dd/MM/yyyy",
    "ar-SY": "dd/MM/yyyy",
    "en-BZ": "dd/MM/yyyy",
    "es-PE": "dd/MM/yyyy",
    "ar-JO": "dd/MM/yyyy",
    "en-TT": "dd/MM/yyyy",
    "es-AR": "dd/MM/yyyy",
    "ar-LB": "dd/MM/yyyy",
    "en-ZW": "M/d/yyyy",
    "es-EC": "dd/MM/yyyy",
    "ar-KW": "dd/MM/yyyy",
    "en-PH": "M/d/yyyy",
    "es-CL": "dd-MM-yyyy",
    "ar-AE": "dd/MM/yyyy",
    "es-UY": "dd/MM/yyyy",
    "ar-BH": "dd/MM/yyyy",
    "es-PY": "dd/MM/yyyy",
    "ar-QA": "dd/MM/yyyy",
    "es-BO": "dd/MM/yyyy",
    "es-SV": "dd/MM/yyyy",
    "es-HN": "dd/MM/yyyy",
    "es-NI": "dd/MM/yyyy",
    "es-PR": "dd/MM/yyyy",
    "am-ET": "d/M/yyyy",
    "tzm-Latn-DZ": "dd-MM-yyyy",
    "iu-Latn-CA": "d/MM/yyyy",
    "sma-NO": "dd.MM.yyyy",
    "mn-Mong-CN": "yyyy/M/d",
    "gd-GB": "dd/MM/yyyy",
    "en-MY": "d/M/yyyy",
    "prs-AF": "dd/MM/yy",
    "bn-BD": "dd-MM-yy",
    "wo-SN": "dd/MM/yyyy",
    "rw-RW": "M/d/yyyy",
    "qut-GT": "dd/MM/yyyy",
    "sah-RU": "MM.dd.yyyy",
    "gsw-FR": "dd/MM/yyyy",
    "co-FR": "dd/MM/yyyy",
    "oc-FR": "dd/MM/yyyy",
    "mi-NZ": "dd/MM/yyyy",
    "ga-IE": "dd/MM/yyyy",
    "se-SE": "yyyy-MM-dd",
    "br-FR": "dd/MM/yyyy",
    "smn-FI": "d.M.yyyy",
    "moh-CA": "M/d/yyyy",
    "arn-CL": "dd-MM-yyyy",
    "ii-CN": "yyyy/M/d",
    "dsb-DE": "d. M. yyyy",
    "ig-NG": "d/M/yyyy",
    "kl-GL": "dd-MM-yyyy",
    "lb-LU": "dd/MM/yyyy",
    "ba-RU": "dd.MM.yy",
    "nso-ZA": "yyyy/MM/dd",
    "quz-BO": "dd/MM/yyyy",
    "yo-NG": "d/M/yyyy",
    "ha-Latn-NG": "d/M/yyyy",
    "fil-PH": "M/d/yyyy",
    "ps-AF": "dd/MM/yy",
    "fy-NL": "d-M-yyyy",
    "ne-NP": "M/d/yyyy",
    "se-NO": "dd.MM.yyyy",
    "iu-Cans-CA": "d/M/yyyy",
    "sr-Latn-RS": "d.M.yyyy",
    "si-LK": "yyyy-MM-dd",
    "sr-Cyrl-RS": "d.M.yyyy",
    "lo-LA": "dd/MM/yyyy",
    "km-KH": "yyyy-MM-dd",
    "cy-GB": "dd/MM/yyyy",
    "bo-CN": "yyyy/M/d",
    "sms-FI": "d.M.yyyy",
    "as-IN": "dd-MM-yyyy",
    "ml-IN": "dd-MM-yy",
    "en-IN": "dd-MM-yyyy",
    "or-IN": "dd-MM-yy",
    "bn-IN": "dd-MM-yy",
    "tk-TM": "dd.MM.yy",
    "bs-Latn-BA": "d.M.yyyy",
    "mt-MT": "dd/MM/yyyy",
    "sr-Cyrl-ME": "d.M.yyyy",
    "se-FI": "d.M.yyyy",
    "zu-ZA": "yyyy/MM/dd",
    "xh-ZA": "yyyy/MM/dd",
    "tn-ZA": "yyyy/MM/dd",
    "hsb-DE": "d. M. yyyy",
    "bs-Cyrl-BA": "d.M.yyyy",
    "tg-Cyrl-TJ": "dd.MM.yy",
    "sr-Latn-BA": "d.M.yyyy",
    "smj-NO": "dd.MM.yyyy",
    "rm-CH": "dd/MM/yyyy",
    "smj-SE": "yyyy-MM-dd",
    "quz-EC": "dd/MM/yyyy",
    "quz-PE": "dd/MM/yyyy",
    "hr-BA": "d.M.yyyy.",
    "sr-Latn-ME": "d.M.yyyy",
    "sma-SE": "yyyy-MM-dd",
    "en-SG": "d/M/yyyy",
    "ug-CN": "yyyy-M-d",
    "sr-Cyrl-BA": "d.M.yyyy",
    "es-US": "M/d/yyyy"
  };

  return formats[navigator.language] || formats[navigator.language.toLowerCase()] || "dd/MM/yyyy";
};

const getLocaleMonthYearString = () => {
  const formats = {
    "ar-SA": "MM/yy",
    "bg-BG": "M.yyyy",
    "ca-ES": "MM/yyyy",
    "zh-TW": "yyyy/M",
    "cs-CZ": "M.yyyy",
    "da-DK": "MM-yyyy",
    "de-DE": "MM.yyyy",
    "el-GR": "M/yyyy",
    "en-US": "M/yyyy",
    "fi-FI": "M.yyyy",
    "fr-FR": "MM/yyyy",
    "he-IL": "MM/yyyy",
    "hu-HU": "yyyy. MM.",
    "is-IS": "M.yyyy",
    "it-IT": "MM/yyyy",
    "ja-JP": "yyyy/MM",
    "ko-KR": "yyyy-MM",
    "nl-NL": "M-yyyy",
    "nb-NO": "MM.yyyy",
    "pl-PL": "yyyy-MM",
    "pt-BR": "M/yyyy",
    "ro-RO": "MM.yyyy",
    "ru-RU": "MM.yyyy",
    "hr-HR": "M.yyyy",
    "sk-SK": "M. yyyy",
    "sq-AL": "yyyy-MM",
    "sv-SE": "yyyy-MM",
    "th-TH": "M/yyyy",
    "tr-TR": "MM.yyyy",
    "ur-PK": "MM/yyyy",
    "id-ID": "MM/yyyy",
    "uk-UA": "MM.yyyy",
    "be-BY": "MM.yyyy",
    "sl-SI": "M.yyyy",
    "et-EE": "MM.yyyy",
    "lv-LV": "yyyy.MM.",
    "lt-LT": "yyyy.MM",
    "fa-IR": "MM/yyyy",
    "vi-VN": "MM/yyyy",
    "hy-AM": "MM.yyyy",
    "az-Latn-AZ": "MM.yyyy",
    "eu-ES": "yyyy/MM",
    "mk-MK": "MM.yyyy",
    "af-ZA": "yyyy/MM",
    "ka-GE": "MM.yyyy",
    "fo-FO": "MM-yyyy",
    "hi-IN": "MM-yyyy",
    "ms-MY": "MM/yyyy",
    "kk-KZ": "MM.yyyy",
    "ky-KG": "MM.yy",
    "sw-KE": "M/yyyy",
    "uz-Latn-UZ": "MM yyyy",
    "tt-RU": "MM.yyyy",
    "pa-IN": "MM-yy",
    "gu-IN": "MM-yy",
    "ta-IN": "MM-yyyy",
    "te-IN": "MM-yy",
    "kn-IN": "MM-yy",
    "mr-IN": "MM-yyyy",
    "sa-IN": "MM-yyyy",
    "mn-MN": "yy.MM",
    "gl-ES": "MM/yy",
    "kok-IN": "MM-yyyy",
    "syr-SY": "MM/yyyy",
    "dv-MV": "MM/yy",
    "ar-IQ": "MM/yyyy",
    "zh-CN": "yyyy/M",
    "de-CH": "MM.yyyy",
    "en-GB": "MM/yyyy",
    "es-MX": "MM/yyyy",
    "fr-BE": "MM/yyyy",
    "it-CH": "MM.yyyy",
    "nl-BE": "MM/yyyy",
    "nn-NO": "MM.yyyy",
    "pt-PT": "MM-yyyy",
    "sr-Latn-CS": "M.yyyy",
    "sv-FI": "M.yyyy",
    "az-Cyrl-AZ": "MM.yyyy",
    "ms-BN": "MM/yyyy",
    "uz-Cyrl-UZ": "MM.yyyy",
    "ar-EG": "MM/yyyy",
    "zh-HK": "M/yyyy",
    "de-AT": "MM.yyyy",
    "en-AU": "MM/yyyy",
    "es-ES": "MM/yyyy",
    "fr-CA": "yyyy-MM",
    "sr-Cyrl-CS": "M.yyyy",
    "ar-LY": "MM/yyyy",
    "zh-SG": "M/yyyy",
    "de-LU": "MM.yyyy",
    "en-CA": "MM/yyyy",
    "es-GT": "MM/yyyy",
    "fr-CH": "MM.yyyy",
    "ar-DZ": "MM-yyyy",
    "zh-MO": "M/yyyy",
    "de-LI": "MM.yyyy",
    "en-NZ": "MM/yyyy",
    "es-CR": "MM/yyyy",
    "fr-LU": "MM/yyyy",
    "ar-MA": "MM-yyyy",
    "en-IE": "MM/yyyy",
    "es-PA": "MM/yyyy",
    "fr-MC": "MM/yyyy",
    "ar-TN": "MM-yyyy",
    "en-ZA": "yyyy/MM",
    "es-DO": "MM/yyyy",
    "ar-OM": "MM/yyyy",
    "en-JM": "MM/yyyy",
    "es-VE": "MM/yyyy",
    "ar-YE": "MM/yyyy",
    "en-029": "MM/yyyy",
    "es-CO": "MM/yyyy",
    "ar-SY": "MM/yyyy",
    "en-BZ": "MM/yyyy",
    "es-PE": "MM/yyyy",
    "ar-JO": "MM/yyyy",
    "en-TT": "MM/yyyy",
    "es-AR": "MM/yyyy",
    "ar-LB": "MM/yyyy",
    "en-ZW": "M/yyyy",
    "es-EC": "MM/yyyy",
    "ar-KW": "MM/yyyy",
    "en-PH": "M/yyyy",
    "es-CL": "MM-yyyy",
    "ar-AE": "MM/yyyy",
    "es-UY": "MM/yyyy",
    "ar-BH": "MM/yyyy",
    "es-PY": "MM/yyyy",
    "ar-QA": "MM/yyyy",
    "es-BO": "MM/yyyy",
    "es-SV": "MM/yyyy",
    "es-HN": "MM/yyyy",
    "es-NI": "MM/yyyy",
    "es-PR": "MM/yyyy",
    "am-ET": "M/yyyy",
    "tzm-Latn-DZ": "MM-yyyy",
    "iu-Latn-CA": "MM/yyyy",
    "sma-NO": "MM.yyyy",
    "mn-Mong-CN": "yyyy/M",
    "gd-GB": "MM/yyyy",
    "en-MY": "M/yyyy",
    "prs-AF": "MM/yy",
    "bn-BD": "MM-yy",
    "wo-SN": "MM/yyyy",
    "rw-RW": "M/yyyy",
    "qut-GT": "MM/yyyy",
    "sah-RU": "MM.yyyy",
    "gsw-FR": "MM/yyyy",
    "co-FR": "MM/yyyy",
    "oc-FR": "MM/yyyy",
    "mi-NZ": "MM/yyyy",
    "ga-IE": "MM/yyyy",
    "se-SE": "yyyy-MM",
    "br-FR": "MM/yyyy",
    "smn-FI": "M.yyyy",
    "moh-CA": "M/yyyy",
    "arn-CL": "MM-yyyy",
    "ii-CN": "yyyy/M",
    "dsb-DE": "M. yyyy",
    "ig-NG": "M/yyyy",
    "kl-GL": "MM-yyyy",
    "lb-LU": "MM/yyyy",
    "ba-RU": "MM.yy",
    "nso-ZA": "yyyy/MM",
    "quz-BO": "MM/yyyy",
    "yo-NG": "M/yyyy",
    "ha-Latn-NG": "M/yyyy",
    "fil-PH": "M/yyyy",
    "ps-AF": "MM/yy",
    "fy-NL": "M-yyyy",
    "ne-NP": "M/yyyy",
    "se-NO": "MM.yyyy",
    "iu-Cans-CA": "M/yyyy",
    "sr-Latn-RS": "M.yyyy",
    "si-LK": "yyyy-MM",
    "sr-Cyrl-RS": "M.yyyy",
    "lo-LA": "MM/yyyy",
    "km-KH": "yyyy-MM",
    "cy-GB": "MM/yyyy",
    "bo-CN": "yyyy/M",
    "sms-FI": "M.yyyy",
    "as-IN": "MM-yyyy",
    "ml-IN": "MM-yy",
    "en-IN": "MM-yyyy",
    "or-IN": "MM-yy",
    "bn-IN": "MM-yy",
    "tk-TM": "MM.yy",
    "bs-Latn-BA": "M.yyyy",
    "mt-MT": "MM/yyyy",
    "sr-Cyrl-ME": "M.yyyy",
    "se-FI": "M.yyyy",
    "zu-ZA": "yyyy/MM",
    "xh-ZA": "yyyy/MM",
    "tn-ZA": "yyyy/MM",
    "hsb-DE": "M. yyyy",
    "bs-Cyrl-BA": "M.yyyy",
    "tg-Cyrl-TJ": "MM.yy",
    "sr-Latn-BA": "M.yyyy",
    "smj-NO": "MM.yyyy",
    "rm-CH": "MM/yyyy",
    "smj-SE": "yyyy-MM",
    "quz-EC": "MM/yyyy",
    "quz-PE": "MM/yyyy",
    "hr-BA": "M.yyyy.",
    "sr-Latn-ME": "M.yyyy",
    "sma-SE": "yyyy-MM",
    "en-SG": "M/yyyy",
    "ug-CN": "yyyy-M",
    "sr-Cyrl-BA": "M.yyyy",
    "es-US": "M/yyyy"
  };

  return formats[navigator.language] || formats[navigator.language.toLowerCase()] || "MM/yyyy";
};

const dateToString = date => {
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  return [date.getFullYear(), month, day].join("-");
};

export const localeService = {
  getLocaleDateString,
  getLocaleMonthYearString,
  dateToString,
  locale: enGB
};
