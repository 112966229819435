import React from "react";
import { Modal, Button } from "react-bootstrap";

const AddTagConfirmation = ({ show, onHide, onAdd, tag }) => {
  return (
    <div>
      <div className="AddTagConfirmation">
        <Modal
          show={show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Confirm tag adding</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please confirm adding the tag{" "}
              <span style={{ backgroundColor: "rgba(135, 141, 157, 0.22)" }} className="text-info rounded p-1">
                {tag}
              </span>{" "}
              to the expenses. After confirmation, the tag will be <strong>added to all</strong> selected expenses.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="info" onClick={onAdd}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AddTagConfirmation;
