import axios from "axios";
import config from "../config";

// cookie
const setCookie = (name, value, days = 7) => {
  let expires;
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = name => {
  const nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const removeCookie = name => {
  setCookie(name, "", -1);
};

// authorization
const setTokensInCookies = result => {
  setCookie("refreshToken", result.data.refreshToken);
  setCookie("jwtToken", "Bearer " + result.data.token);
};

const removeCookies = () => {
  removeCookie("refreshToken");
  removeCookie("jwtToken");
};

const login = (email, password) => {
  return axios
    .post(config.apiUrl + "/api/auth/login", { email, password })
    .then(result => setTokensInCookies(result))
    .catch(error => {
      throw Error(error.response.data.message);
    });
};

// headers
axios.interceptors.request.use(function(requestConfig) {
  if (requestConfig.url.includes(config.apiUrl)) {
    requestConfig.headers.Authorization = getCookie("jwtToken");
    requestConfig.headers["Cache-Control"] = "no-cache,no-store,must-revalidate,max-age=0";
  }
  return requestConfig;
});

export const authService = {
  getCookie,
  setTokensInCookies,
  removeCookies,
  login
};
