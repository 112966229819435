import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as QueryString from "query-string";
import { DropdownButton, ButtonGroup, Dropdown, Table, Button, ButtonToolbar } from "react-bootstrap";
import AlertMessage from "../AlertMessage/AlertMessage";
import ExpensesFilters from "../ExpensesFilters/ExpensesFilters";
import UserCard from "../UserCard/UserCard";
import Expense from "../Expense/Expense";
import ExpenseEditor from "../ExpenseEditor/ExpenseEditor";
import RefillEditor from "../RefillEditor/RefillEditor";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import RemoveTagsConfirmation from "../RemoveTagsConfirmation/RemoveTagsConfirmation";
import AddTagConfirmation from "../AddTagConfirmation/AddTagConfirmation";
import TransferConfirmation from "../TransferConfirmation/TransferConfirmation";
import Pages from "../Pages/Pages";
import "./Expenses.scss";

import { expenseService } from "../../services/expenseService";

import { authorizedUserSelector, getMe } from "../../reducers";

const Expenses = ({ location, authorizedUser, getMe }) => {
  const [state, setState] = useState({ defaultTags: [], minStartDate: new Date("1996-09-02") });

  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [dollarSwitch, setDollarSwitch] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const [expenseEditorShow, setExpenseEditorShow] = useState(false);
  const [refillEditorShow, setRefillEditorShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

  const [showTags, setShowTags] = useState(false);
  const [removeConfirmationShow, setRemoveConfirmationShow] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const [hoveredDep, setHoveredDep] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");

  const getCategoriesArrayFromQuery = () => {
    const categoriesFromParsedQueryString = QueryString.parse(location.search).categories;
    if (categoriesFromParsedQueryString) {
      if (Array.isArray(categoriesFromParsedQueryString)) return [...categoriesFromParsedQueryString];
      else return [categoriesFromParsedQueryString];
    } else {
      return null;
    }
  };

  const getFilters = () => {
    const categories = getCategoriesArrayFromQuery();
    let filters = Object.assign({}, QueryString.parse(location.search), categories && { categories });

    // dates
    if (filters.start && isNaN(Date.parse(filters.start))) delete filters.start;
    if (filters.end && isNaN(Date.parse(filters.end))) delete filters.end;
    if (filters.start && filters.end && new Date(filters.end).getTime() < new Date(filters.start).getTime()) {
      delete filters.start;
      delete filters.end;
    }

    // type
    if (filters.type && !(filters.type === "expense" || filters.type === "refill")) delete filters.type;
    if (filters.type && filters.type === "refill") {
      delete filters.department;
      delete filters.categories;
    }

    // departments and categories
    if (filters.department && !authorizedUser.departments.find(dep => dep._id === filters.department)) {
      delete filters.department;
    }
    if (!filters.department && filters.categories) delete filters.categories;
    if (filters.department && filters.categories) {
      const selectedDepartment = authorizedUser.departments.find(dep => filters.department === dep._id);
      const validCategories = filters.categories.reduce((acc, filCat) => {
        if (selectedDepartment.categories.find(cat => cat._id === filCat)) return [...acc, filCat];
        else return acc;
      }, []);
      if (validCategories.length) filters.categories = validCategories;
      else delete filters.categories;
    }

    // users
    if (filters.user && authorizedUser.super) filters.showUsers = "true";
    if (filters.showUsers && !authorizedUser.super) delete filters.showUsers;
    if (filters.showUsers === "true" && !filters.user) delete filters.type;

    return filters;
  };

  const [filters, setFilters] = useState(getFilters());

  const onError = () => {
    setErrorType("");
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );
  };

  const loadExpenses = (currentPage, pageLimit, filters) => {
    expenseService
      .getExpenses(currentPage, pageLimit, filters)
      .then(({ data }) => {
        window.scrollTo(0, 0);
        setSelectedExpenses([]);
        setState({
          ...data,
          minStartDate: !isNaN(Date.parse(data.minDate)) ? new Date(data.minDate) : new Date("1996-09-02")
        });
      })
      .then(getMe)
      .catch(error => {
        if (error.message !== "CANCELED") onError();
      });
  };

  const onExpensesChange = () => {
    setCurrentPage(1);
    loadExpenses(currentPage, pageLimit, filters);
  };

  const transferExpensesToCategory = category => {
    setSelectedCategory(null);
    expenseService
      .transferExpensesToCategory(selectedExpenses, category)
      .then(res => {
        setSelectedExpenses([]);

        if (res.data.users.length) {
          setErrorType("info");

          const newErrorMessage = (
            <span>
              <small className="mr-4">{new Date().toLocaleTimeString()}</small>
              <span>{"Users without access to this category:"}</span>
              <span className="font-weight-bold">
                {res.data.users.map(user => " " + user.role.toLowerCase() + " " + user.name).join()}
              </span>
            </span>
          );
          setErrorMessage(newErrorMessage);
        }
      })
      .then(onExpensesChange)
      .catch(onError);
  };

  const removeTagsFromExpenses = () => {
    setRemoveConfirmationShow(false);
    expenseService
      .removeTagsFromExpenses(selectedExpenses)
      .then(onExpensesChange)
      .catch(onError);
  };

  const addTagToExpenses = tag => {
    setSelectedTag(null);
    expenseService
      .addTagToExpenses(selectedExpenses, tag)
      .then(onExpensesChange)
      .catch(onError);
  };

  const deleteExpenses = () => {
    setDeleteConfirmationShow(false);
    expenseService
      .deleteExpenses(selectedExpenses)
      .then(onExpensesChange)
      .catch(onError);
  };

  useEffect(() => loadExpenses(currentPage, pageLimit, filters), [currentPage, pageLimit, filters]);

  useEffect(() => setCurrentPage(1), [pageLimit, filters]);

  useEffect(() => setFilters(getFilters()), [location.search]);

  const onCurrentPageChange = number => setCurrentPage(number);
  const onPageLimitChange = number => setPageLimit(number);

  const onSelectAllExpenses = () => {
    if (selectedExpenses.length === 0) setSelectedExpenses(state.expenses.map(exp => exp._id));
    else setSelectedExpenses([]);
  };

  const onSelectExpense = expId => {
    if (selectedExpenses.find(selExp => selExp === expId))
      setSelectedExpenses(selectedExpenses.filter(selExp => selExp !== expId));
    else setSelectedExpenses([...selectedExpenses, expId]);
  };

  return state.expenses ? (
    <div>
      <AlertMessage message={errorMessage} type={errorType} />
      <div className="Expenses">
        <div className="header">
          <ExpensesFilters
            filters={filters}
            authorizedUser={authorizedUser}
            tags={state.defaultTags}
            users={state.users}
            minStartDate={state.minStartDate}
          />
          {authorizedUser.role === "MANAGER" ? (
            <DropdownButton
              alignRight
              id="dropdown-menu-align-right"
              as={ButtonGroup}
              variant="light"
              title={
                <span>
                  <i className="fa fa-plus" aria-hidden="true" />
                </span>
              }
            >
              <Dropdown.Item onClick={() => setExpenseEditorShow(true)}>Expense</Dropdown.Item>
              <Dropdown.Item onClick={() => setRefillEditorShow(true)}>Refill</Dropdown.Item>
              {expenseEditorShow ? (
                <ExpenseEditor
                  show={expenseEditorShow}
                  onHide={() => setExpenseEditorShow(false)}
                  authorizedUser={authorizedUser}
                  defaultTags={state.defaultTags}
                  editingDays={state.editingDays}
                  defaultState={{
                    ...(filters.categories && {
                      category: filters.categories.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))[0]
                    }),
                    ...(filters.tag && { tag: filters.tag })
                  }}
                  onAccept={onExpensesChange}
                />
              ) : null}
              {refillEditorShow ? (
                <RefillEditor
                  show={refillEditorShow}
                  onHide={() => setRefillEditorShow(false)}
                  authorizedUser={authorizedUser}
                  editingDays={state.editingDays}
                  onAccept={onExpensesChange}
                />
              ) : null}
            </DropdownButton>
          ) : (
            <ButtonToolbar>
              <Button variant="light" onClick={() => setExpenseEditorShow(true)}>
                <i className="fa fa-plus" aria-hidden="true" />
              </Button>
              {expenseEditorShow ? (
                <ExpenseEditor
                  show={expenseEditorShow}
                  onHide={() => setExpenseEditorShow(false)}
                  authorizedUser={authorizedUser}
                  defaultTags={state.defaultTags}
                  editingDays={state.editingDays}
                  defaultState={{
                    ...(filters.categories && {
                      category: filters.categories.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))[0]
                    }),
                    ...(filters.tag && { tag: filters.tag })
                  }}
                  onAccept={onExpensesChange}
                />
              ) : null}
            </ButtonToolbar>
          )}
        </div>

        {state.user ? <UserCard data={state.user} /> : null}

        <Table hover variant="light" className="expenses-list">
          <thead className="thead-light">
            <tr>
              {authorizedUser.role === "ADMIN" ? (
                <th width="1%" className="p-0">
                  <div className="checkbox-expense d-flex align-items-center" onClick={onSelectAllExpenses}>
                    {selectedExpenses.length === 0 ? (
                      <i className="fa fa-square-o smaller text-muted" aria-hidden="true" />
                    ) : selectedExpenses.length === state.expenses.length ? (
                      <i className="fa fa-check-square bigger text-dark font-weight-bold" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-minus-square bigger text-dark font-weight-bold" aria-hidden="true" />
                    )}
                  </div>
                </th>
              ) : null}
              <th width="10%">
                <div className="d-flex align-items-center">
                  <span>Money</span>
                  {authorizedUser.role === "ADMIN" ? (
                    <div
                      className="d-flex align-items-center ml-2 btn p-0"
                      onClick={() => setDollarSwitch(!dollarSwitch)}
                    >
                      {dollarSwitch ? (
                        <i className="fa fa-toggle-on text-primary font-weight-bold" aria-hidden="true" />
                      ) : (
                        <i className="fa fa-toggle-off text-muted font-weight-bold" aria-hidden="true" />
                      )}
                      <small
                        className={
                          dollarSwitch ? "ml-1 font-weight-bold dollar-label" : "ml-1 font-weight-bold text-muted"
                        }
                      >
                        $
                      </small>
                    </div>
                  ) : null}
                </div>
              </th>
              <th width="10%">Date</th>
              <th>Department & Category</th>
              <th width="1%">Tags</th>
              <th width="20%">Comment</th>
              {authorizedUser.role === "ADMIN" || authorizedUser.super ? <th width="1%">Author</th> : null}
              <th width="1%">
                {authorizedUser.role === "ADMIN" && selectedExpenses.length !== 0 ? (
                  <ButtonToolbar className="d-flex flex-nowrap justify-content-end">
                    <DropdownButton
                      disabled={selectedExpenses.every(selExp =>
                        state.expenses.filter(exp => !exp.category).find(exp => selExp === exp._id)
                      )}
                      id="dropdown-mix-menu-align-right"
                      alignRight
                      as={ButtonGroup}
                      variant="light"
                      title={
                        <span>
                          <i className="fa fa-tag text-dark" aria-hidden="true" />
                        </span>
                      }
                    >
                      <Dropdown.Item onClick={() => setRemoveConfirmationShow(true)}>
                        <i className="fa fa-eraser tag-icon" aria-hidden="true" />
                        Remove tags
                      </Dropdown.Item>

                      <DropdownButton
                        id="dropdown-mix-menu-align-left"
                        drop="left"
                        className="submenu"
                        variant="light"
                        title={
                          <span>
                            <i className="fa fa-angle-left pl-1 tag-icon" aria-hidden="true" />
                            Add tag
                          </span>
                        }
                        onMouseEnter={() => setShowTags(true)}
                        onMouseLeave={() => setShowTags(false)}
                        show={showTags}
                      >
                        {state.defaultTags.map(tag => (
                          <Dropdown.Item key={tag._id} onClick={() => setSelectedTag(tag)}>
                            {tag.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </DropdownButton>

                    <Button variant="light" onClick={() => setDeleteConfirmationShow(true)}>
                      <i className="fa fa-trash text-dark font-weight-bold" aria-hidden="true" />
                    </Button>

                    <DropdownButton
                      disabled={selectedExpenses.every(selExp =>
                        state.expenses.filter(exp => !exp.category).find(exp => selExp === exp._id)
                      )}
                      id="dropdown-menu-align-right"
                      alignRight
                      as={ButtonGroup}
                      variant="light"
                      title={
                        <span>
                          <i className="fa fa-arrow-right text-dark" aria-hidden="true" />
                        </span>
                      }
                    >
                      {authorizedUser.departments.map(dep => (
                        <DropdownButton
                          key={dep._id}
                          id={"dropdown-menu-align-left" + dep._id}
                          drop="left"
                          className="submenu"
                          variant="light"
                          title={
                            <span>
                              <i className="fa fa-angle-left mr-3" aria-hidden="true" />
                              {dep.name}
                            </span>
                          }
                          onMouseEnter={() => setHoveredDep(dep._id)}
                          onMouseLeave={() => setHoveredDep(null)}
                          show={hoveredDep === dep._id}
                        >
                          {dep.categories.map(cat => (
                            <Dropdown.Item key={cat._id} onClick={() => setSelectedCategory(cat)}>
                              {cat.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      ))}
                    </DropdownButton>
                    {removeConfirmationShow ? (
                      <RemoveTagsConfirmation
                        show={removeConfirmationShow}
                        onHide={() => setRemoveConfirmationShow(false)}
                        onRemove={removeTagsFromExpenses}
                      />
                    ) : null}
                    {selectedTag ? (
                      <AddTagConfirmation
                        show={!!selectedTag}
                        onHide={() => setSelectedTag(null)}
                        onAdd={() => addTagToExpenses(selectedTag._id)}
                        tag={selectedTag.name}
                      />
                    ) : null}
                    {deleteConfirmationShow ? (
                      <DeleteConfirmation
                        show={deleteConfirmationShow}
                        onHide={() => setDeleteConfirmationShow(false)}
                        onDelete={deleteExpenses}
                      />
                    ) : null}
                    {selectedCategory ? (
                      <TransferConfirmation
                        show={!!selectedCategory}
                        onHide={() => setSelectedCategory(null)}
                        onTransfer={() => transferExpensesToCategory(selectedCategory._id)}
                        category={selectedCategory.name}
                      />
                    ) : null}
                  </ButtonToolbar>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {state.expenses.map(expense => (
              <Expense
                key={expense._id}
                authorizedUser={authorizedUser}
                defaultTags={state.defaultTags}
                data={expense}
                editingDays={state.editingDays}
                selected={selectedExpenses.find(selExp => selExp === expense._id)}
                onSelectExpense={() => onSelectExpense(expense._id)}
                dollarSwitch={dollarSwitch}
                onChange={onExpensesChange}
                onError={onError}
              />
            ))}
          </tbody>
        </Table>

        <Pages
          currentPage={currentPage}
          pageLimit={pageLimit}
          records={state.expenses.length}
          totalPages={state.totalPages}
          onCurrentPageChange={onCurrentPageChange}
          onPageLimitChange={onPageLimitChange}
        />
      </div>
    </div>
  ) : null;
};

const mapStateToProps = state => {
  return {
    authorizedUser: authorizedUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMe: () => dispatch(getMe())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
