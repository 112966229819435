import React, { useState } from "react";
import Select from "react-select";

const RequiredSelect = props => {
  const [selectRef, setSelectRef] = useState(null);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25);" : "none",
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      outline: 0,
      fontSize: "0.9rem",
      "&:hover": { borderColor: state.isFocused ? "#80bdff" : "#ced4da" }
    }),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
    groupHeading: base => ({
      color: "#495057"
    })
  };

  return (
    <div>
      {!props.isDisabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            position: "absolute"
          }}
          value={props.value}
          onChange={() => {}}
          onFocus={() => selectRef.focus()}
          required={props.required}
        />
      )}
      <Select {...props} ref={ref => setSelectRef(ref)} styles={customStyles} onChange={props.onChange} />
    </div>
  );
};

export default RequiredSelect;
