import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Nav } from "react-bootstrap";
import UsersEditor from "./UsersEditor/UsersEditor";
import DepartmentsEditor from "./DepartmentsEditor/DepartmentsEditor";
import TagsEditor from "./TagsEditor/TagsEditor";
import "./AdminPanel.scss";

import { authorizedUserSelector, getMe } from "../../reducers";

const AdminPanel = ({ authorizedUser, getMe }) => {
  return (
    <div className="AdminPanel">
      <div className="admin-tabs">
        <div className="admin-tabs-nav">
          <Nav variant="tabs">
            <Nav.Item className="nav-link" as={NavLink} to="/admin-panel/users">
              Users
            </Nav.Item>
            <Nav.Item className="nav-link" as={NavLink} to="/admin-panel/departments">
              Departments
            </Nav.Item>
            <Nav.Item className="nav-link" as={NavLink} to="/admin-panel/tags">
              Tags
            </Nav.Item>
          </Nav>
        </div>

        <Switch>
          <Route exact path="/admin-panel/users">
            <UsersEditor authorizedUser={authorizedUser} getMe={getMe} />
          </Route>
          <Route exact path="/admin-panel/departments">
            <DepartmentsEditor authorizedUser={authorizedUser} getMe={getMe} />
          </Route>
          <Route exact path="/admin-panel/tags">
            <TagsEditor authorizedUser={authorizedUser} getMe={getMe} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authorizedUser: authorizedUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMe: () => dispatch(getMe())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
