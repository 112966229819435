import axios from "axios";
import config from "../config";

const getDepartments = () => axios.get(config.apiUrl + `/api/departments/`);
const createDepartment = data => axios.post(config.apiUrl + `/api/department`, data);
const updateDepartment = (depId, data) => axios.put(config.apiUrl + `/api/department/${depId}`, data);
const deleteDepartment = depId => axios.delete(config.apiUrl + `/api/department/${depId}`);

export const departmentService = {
  getDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment
};
