import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import AlertMessage from "../../AlertMessage/AlertMessage";
import UserEditor from "./UserEditor/UserEditor";
import User from "./User/User";
import "./UsersEditor.scss";

import { userService } from "../../../services/userService";

const UsersEditor = ({ authorizedUser, getMe }) => {
  const [users, setUsers] = useState(null);
  const [userEditorShow, setUserEditorShow] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const requestUsers = () => {
    userService
      .getUsers()
      .then(res => setUsers(res.data))
      .then(getMe)
      .catch(onError);
  };

  useEffect(() => {
    requestUsers();
  }, []);

  const sortUsersByRole = users => users.sort((a, b) => (a.role > b.role ? 1 : b.role > a.role ? -1 : 0));

  return users ? (
    <div>
      <AlertMessage message={errorMessage} type="error" />
      <div className="UsersEditor">
        <div className="users-header d-flex justify-content-end">
          <Button variant="success" onClick={() => setUserEditorShow(true)}>
            New user
          </Button>
          {userEditorShow ? (
            <UserEditor
              show={userEditorShow}
              onHide={() => setUserEditorShow(false)}
              authorizedUser={authorizedUser}
              onAccept={requestUsers}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead className="text-muted small">
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>Role</td>
              <td align="right">Balance</td>
              <td>Currency</td>
              <td colSpan="2" width="1%">
                Departments
              </td>
            </tr>
          </thead>
          <tbody>
            {sortUsersByRole(users).map(user => (
              <User
                key={user._id}
                authorizedUser={authorizedUser}
                user={user}
                onChange={requestUsers}
                onError={onError}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  ) : null;
};

export default UsersEditor;
