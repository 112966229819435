import React from "react";
import { Card } from "react-bootstrap";
import "./UserCard.scss";
import { moneyService } from "../../services/moneyService";

const UserCard = ({ data }) => {
  const getCurrencyIcon = type => (type === "USD" ? "$" : "₴");

  return (
    <Card className="UserCard mb-3" text="dark">
      <Card.Header className="d-flex flex-wrap">
        <div className="mr-5">{data.name}</div>

        <div className="mr-5">
          <a href={`mailto:${data.email}`} className="text-dark">
            {data.email}
          </a>
        </div>

        <div className="mr-1">
          <span className={data.role !== "ADMIN" && data.super ? "super-user p-1 rounded" : "p-1"}>
            {data.role.charAt(0) + data.role.slice(1).toLowerCase()}
          </span>
        </div>
        <div className="mr-1">
          {data.role === "MANAGER" ? moneyService.toFloatStringWithSpaces(data.balance) : null}
        </div>
        <div className="mr-5">{getCurrencyIcon(data.currency)}</div>

        <div>{data.departments.map(dep => " " + dep.name).join()}</div>
      </Card.Header>
    </Card>
  );
};

export default UserCard;
