import { authService } from "../services/authService";
import { getMe, setHasAuthBeenCheckedToTrue } from "../reducers";

export default function checkAuth(store) {
  if (authService.getCookie("jwtToken")) {
    store.dispatch(getMe()).finally(() => store.dispatch(setHasAuthBeenCheckedToTrue()));
  } else {
    store.dispatch(setHasAuthBeenCheckedToTrue());
  }
}
