import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import "./AlertMessage.scss";

const AlertMessage = ({ message, type }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
      const timer = setTimeout(() => setShow(false), 7000);
      return () => clearTimeout(timer);
    } else {
      setShow(false);
    }
  }, [message]);

  const onClose = () => {
    setShow(false);
  };

  const getClass = type => {
    if (type === "info") return "info";
    else if (type === "warning") return "warning";
    else if (type === "secondary") return "secondary";
    else return "danger";
  };

  return (
    <Alert show={show} variant={getClass(type)} onClose={onClose} dismissible>
      {message}
    </Alert>
  );
};

export default AlertMessage;
