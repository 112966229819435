import React from "react";
import ReactDOM from "react-dom";
import logger from "redux-logger";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import rootReducer from "./reducers";
import checkRefreshToken from "./utils/checkRefreshToken";
import checkAuth from "./utils/checkAuth";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "./index.scss";

const storeOptions =
  process.env.NODE_ENV !== "production"
    ? {
        reducer: rootReducer,
        middleware: [...getDefaultMiddleware(), logger]
      }
    : {
        reducer: rootReducer,
        middleware: getDefaultMiddleware(),
        devTools: false
      };

const store = configureStore(storeOptions);

const history = createBrowserHistory();

checkRefreshToken(store);
checkAuth(store);

const app = (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
