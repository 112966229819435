import React, { useState } from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import UserEditor from "../UserEditor/UserEditor";
import DeleteConfirmation from "../../../DeleteConfirmation/DeleteConfirmation";
import { userService } from "../../../../services/userService";
import { moneyService } from "../../../../services/moneyService";

const User = ({ authorizedUser, user, onChange, onError }) => {
  const [userEditorShow, setUserEditorShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

  const deleteUser = userId => {
    setDeleteConfirmationShow(false);
    userService
      .deleteUser(userId)
      .then(onChange)
      .catch(onError);
  };

  const getCurrencyIcon = type => (type === "USD" ? "$" : "₴");

  return (
    <tr>
      <td>{user.name}</td>
      <td>
        <a href={`mailto:${user.email}`} className="text-dark">
          {user.email}
        </a>
      </td>
      <td>
        <span className={user.role !== "ADMIN" && user.super ? "super-user p-1 rounded" : "p-1"}>
          {user.role.charAt(0) + user.role.slice(1).toLowerCase()}
        </span>
      </td>
      <td align="right" className="balance-money">
        {user.role === "MANAGER" ? moneyService.toFloatStringWithSpaces(user.balance) : null}
      </td>
      <td>{getCurrencyIcon(user.currency)}</td>
      <td>
        {user.role !== "ADMIN" ? (
          user.departments.map(dep => {
            return (
              <div key={`${user._id}~${dep._id}`} className="text-nowrap">
                {dep.name}
              </div>
            );
          })
        ) : (
          <span className="text-muted">All</span>
        )}
      </td>
      <td className="p-2">
        <ButtonToolbar className="d-flex flex-nowrap">
          <Button variant="light" onClick={() => setUserEditorShow(true)}>
            <i className="fa fa-pencil-square-o" aria-hidden="true" />
          </Button>

          <Button
            variant="light"
            disabled={!user.accessToDeletion || authorizedUser._id === user._id}
            onClick={() => setDeleteConfirmationShow(true)}
          >
            <i className="fa fa-trash-o" aria-hidden="true" />
          </Button>
          {userEditorShow ? (
            <UserEditor
              show={userEditorShow}
              onHide={() => setUserEditorShow(false)}
              data={user}
              authorizedUser={authorizedUser}
              onAccept={onChange}
            />
          ) : null}
          {deleteConfirmationShow ? (
            <DeleteConfirmation
              show={deleteConfirmationShow}
              onHide={() => setDeleteConfirmationShow(false)}
              onDelete={() => deleteUser(user._id)}
            />
          ) : null}
        </ButtonToolbar>
      </td>
    </tr>
  );
};

export default User;
