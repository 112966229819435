import axios from "axios";
import config from "../config";

const getTags = () => axios.get(config.apiUrl + `/api/tags/`);
const createTag = data => axios.post(config.apiUrl + `/api/tag`, data);
const updateTag = (tagId, data) => axios.put(config.apiUrl + `/api/tag/${tagId}`, data);
const deleteTag = tagId => axios.delete(config.apiUrl + `/api/tag/${tagId}`);

export const tagService = {
  getTags,
  createTag,
  updateTag,
  deleteTag
};
