import React, { useState, useEffect } from "react";
import { ListGroup, Button } from "react-bootstrap";
import AlertMessage from "../../AlertMessage/AlertMessage";
import DepartmentEditor from "./DepartmentEditor/DepartmentEditor";
import Department from "./Department/Department";
import "./DepartmentsEditor.scss";

import { departmentService } from "../../../services/departmentService";

const DepartmentsEditor = ({ getMe }) => {
  const [departments, setDepartments] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [hoveredDep, setHoveredDep] = useState(null);

  const [departmentEditorShow, setDepartmentEditorShow] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const requestDepartments = () => {
    departmentService
      .getDepartments()
      .then(res => setDepartments(res.data))
      .then(getMe)
      .catch(onError);
  };

  useEffect(() => {
    requestDepartments();
  }, []);

  const isSelectedDep = depId => !!selectedDepartments.find(dep => dep === depId);
  const onSelectDep = depId => {
    if (isSelectedDep(depId)) setSelectedDepartments([...selectedDepartments.filter(dep => dep !== depId)]);
    else setSelectedDepartments([...selectedDepartments, depId]);
  };

  const toggleDepHoverOn = id => setHoveredDep(id);
  const toggleDepHoverOff = () => setHoveredDep(null);
  const isHoveredDep = id => hoveredDep === id;

  return departments ? (
    <div>
      <AlertMessage message={errorMessage} type="error" />
      <div className="DepartmentsEditor">
        <div className="departments-header d-flex flex-column align-items-end">
          <Button variant="success" className="create-bnt" onClick={() => setDepartmentEditorShow(true)}>
            New department
          </Button>
          {departmentEditorShow ? (
            <DepartmentEditor
              show={departmentEditorShow}
              onHide={() => setDepartmentEditorShow(false)}
              onAccept={requestDepartments}
            />
          ) : null}
        </div>
        <ListGroup className="list-group-flush">
          {departments.map(dep => (
            <Department
              key={dep._id}
              dep={dep}
              toggleDepHoverOn={toggleDepHoverOn}
              toggleDepHoverOff={toggleDepHoverOff}
              isSelectedDep={isSelectedDep}
              isHoveredDep={isHoveredDep}
              onSelect={onSelectDep}
              onChange={requestDepartments}
              onError={onError}
            />
          ))}
        </ListGroup>
      </div>
    </div>
  ) : null;
};

export default DepartmentsEditor;
