import axios from "axios";
import config from "../config";

const CancelToken = axios.CancelToken;
let cancel;

const getExpenses = (page, limit, filters) => {
  cancel && cancel();
  return axios
    .post(
      config.apiUrl + `/api/expenses/`,
      { page, limit, filters },
      { cancelToken: new CancelToken(c => (cancel = c)) }
    )
    .catch(error => {
      if (!error.response) throw Error("CANCELED");
      else throw Error(error);
    });
};

const transferExpensesToCategory = (expenses, category) => {
  return axios.put(config.apiUrl + `/api/expenses/category/${category}`, { expenses });
};
const addTagToExpenses = (expenses, tag) => axios.put(config.apiUrl + `/api/expenses/tag/${tag}`, { expenses });
const removeTagsFromExpenses = expenses => axios.delete(config.apiUrl + `/api/expenses/tags`, { data: { expenses } });
const deleteExpenses = expenses => axios.delete(config.apiUrl + `/api/expenses`, { data: { expenses } });

const createExpense = data => axios.post(config.apiUrl + `/api/expense`, data);
const updateExpense = (expId, data) => axios.put(config.apiUrl + `/api/expense/${expId}`, data);
const deleteExpense = expId => axios.delete(config.apiUrl + `/api/expense/${expId}`);

export const expenseService = {
  getExpenses,
  transferExpensesToCategory,
  addTagToExpenses,
  removeTagsFromExpenses,
  deleteExpenses,
  createExpense,
  updateExpense,
  deleteExpense
};
