import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { localeService } from "../../../../services/localeService";

const ProportionalChartFilters = ({ users, minStartDate, onFiltersChange }) => {
  const initialState = {
    startDate: new Date(new Date().setDate(1)),
    endDate: new Date()
  };

  const [startDate, setStartDate] = useState(initialState.startDate);
  const [endDate, setEndDate] = useState(initialState.endDate);
  const [usersValue, setUsersValue] = useState(null);

  useEffect(() => {
    const users = usersValue ? usersValue.map(userVal => userVal.value) : null;

    onFiltersChange({
      dates: [localeService.dateToString(startDate), localeService.dateToString(endDate)],
      users
    });
  }, [startDate, endDate, usersValue]);

  const onUsersChange = (value, { action }) => {
    if (action === "clear") setUsersValue(null);
    else if (value) setUsersValue(value);
    else setUsersValue(null);
  };

  const hasStateSet = () => {
    return (
      localeService.dateToString(startDate) !== localeService.dateToString(initialState.startDate) ||
      localeService.dateToString(endDate) !== localeService.dateToString(initialState.endDate) ||
      usersValue
    );
  };

  const onButtonClick = () => {
    setStartDate(initialState.startDate);
    setEndDate(initialState.endDate);
    setUsersValue(null);
  };

  const getUsersSelectOptions = () => {
    if (users) return users.map(user => ({ value: user._id, label: user.name }));
  };

  const customStyles = {
    control: base => ({
      ...base,
      boxShadow: "none",
      borderColor: "hsl(0,0%,80%) !important",
      fontSize: "0.9rem"
    })
  };

  const StartDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input">
        {value}
      </Button>
      {localeService.dateToString(startDate) !== localeService.dateToString(initialState.startDate) ? (
        <span className="form-clear" onClick={() => setStartDate(initialState.startDate)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </span>
      ) : null}
    </div>
  ));

  const EndDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input">
        {value}
      </Button>
      {localeService.dateToString(endDate) !== localeService.dateToString(initialState.endDate) ? (
        <span className="form-clear" onClick={() => setEndDate(initialState.endDate)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </span>
      ) : null}
    </div>
  ));

  return (
    <Form className="ChartFilters">
      <Form.Group>
        <DatePicker
          minDate={minStartDate}
          maxDate={endDate}
          locale={localeService.locale}
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={localeService.getLocaleDateString()}
          customInput={<StartDateInput />}
        />
      </Form.Group>

      <Form.Group>
        <DatePicker
          minDate={startDate}
          maxDate={initialState.endDate}
          locale={localeService.locale}
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat={localeService.getLocaleDateString()}
          customInput={<EndDateInput />}
        />
      </Form.Group>

      <Form.Group>
        <Select
          styles={customStyles}
          isMulti
          isClearable={true}
          placeholder="Users"
          value={usersValue}
          onChange={onUsersChange}
          options={getUsersSelectOptions()}
          menuPortalTarget={document.querySelector("body")}
        />
      </Form.Group>

      {hasStateSet() ? (
        <Button variant="light" className="bg-white clear-button" onClick={onButtonClick}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </Button>
      ) : null}
    </Form>
  );
};

export default ProportionalChartFilters;
