import React from "react";
import { Modal, Button } from "react-bootstrap";

const UnsavedChangesConfirmation = ({ show, onHide, onDiscard }) => {
  return (
    <div>
      <div className="UnsavedChangesConfirmation">
        <Modal
          show={show}
          onHide={onHide}
          backdrop={false}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Unsaved changes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This tab has unsaved changes. After discarding, it will be <strong>lost.</strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onDiscard}>
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UnsavedChangesConfirmation;
