import React, { useState, useEffect } from "react";
import * as QueryString from "query-string";
import { NavLink, useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "./ExpensesFilters.scss";

import { localeService } from "../../services/localeService";

const ExpensesFilters = ({ filters, authorizedUser, tags, users, minStartDate }) => {
  const history = useHistory();

  const [startDate, setStartDate] = useState(filters.start ? new Date(filters.start) : null);
  const [endDate, setEndDate] = useState(filters.end ? new Date(filters.end) : null);

  const getTypeValue = type => {
    switch (type) {
      case "expense":
        return { value: "expense", label: "Expense" };
      case "refill":
        return { value: "refill", label: "Refill" };
      default:
        return null;
    }
  };

  const getDepartmentSelectOptions = () => authorizedUser.departments.map(dep => ({ value: dep._id, label: dep.name }));

  const getDepartmentValue = department => {
    const departmentSelectOptions = getDepartmentSelectOptions();
    const departmentValue = departmentSelectOptions.find(depVal => depVal.value === department);

    return departmentValue ? departmentValue : null;
  };

  const getCategorySelectOptions = () => {
    const departmentValue = filters.department ? getDepartmentValue(filters.department) : null;
    if (departmentValue !== null) {
      const selectedDepartment = authorizedUser.departments.find(dep => departmentValue.value === dep._id);
      return selectedDepartment.categories.map(cat => ({ value: cat._id, label: cat.name }));
    }
  };

  const getCategoryValue = categories => {
    const categorySelectOptions = getCategorySelectOptions();
    const categoryValue = categorySelectOptions
      .filter(catVal => categories.find(cat => cat === catVal.value))
      .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

    return categoryValue.length ? categoryValue : null;
  };

  const getTagSelectOptions = () => tags.map(tag => ({ value: tag._id, label: tag.name }));

  const getTagValue = tag => {
    const tagSelectOptions = getTagSelectOptions();
    const tagValue = tagSelectOptions.find(tagVal => tagVal.value === tag);

    return tagValue ? tagValue : null;
  };

  const getShowUsersValue = value => value === "true";

  const getUserSelectOptions = () => {
    if (users) return users.map(user => ({ value: user._id, label: user.name, role: user.role }));
  };

  const getUserValue = user => {
    const userSelectOptions = getUserSelectOptions();
    const userValue = userSelectOptions.find(userVal => userVal.value === user);

    return userValue ? userValue : null;
  };

  const [commentValue, setCommentValue] = useState(filters.comment ? filters.comment : "");
  const [showUsers, setShowAllUsers] = useState(filters.showUsers ? getShowUsersValue(filters.showUsers) : false);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value]);

    return debouncedValue;
  }

  const debouncedCommentValue = useDebounce(commentValue, 200);

  useEffect(() => {
    const comment = debouncedCommentValue ? debouncedCommentValue.trim() : null;
    let queryObject = Object.assign({}, filters);
    if (comment) queryObject.comment = comment;
    else delete queryObject.comment;

    history.push(`/?${QueryString.stringify(queryObject)}`);
  }, [debouncedCommentValue]);

  useEffect(() => {
    if (!filters.comment) setCommentValue("");
  }, [filters.comment]);

  useEffect(() => {
    let queryObject = Object.assign({}, filters);
    if (showUsers && authorizedUser.super) {
      queryObject.showUsers = "true";
      if (!queryObject.user) delete queryObject.type;
    } else if (!showUsers && authorizedUser.super) {
      delete queryObject.showUsers;
      delete queryObject.user;
      delete queryObject.type;
    }

    history.push(`/?${QueryString.stringify(queryObject)}`);
  }, [showUsers]);

  useEffect(() => {
    if (!filters.showUsers) setShowAllUsers(false);
  }, [filters.showUsers]);

  useEffect(() => {
    let queryObject = Object.assign({}, filters);
    if (startDate) queryObject.start = localeService.dateToString(startDate);
    else delete queryObject.start;

    history.push(`/?${QueryString.stringify(queryObject)}`);
  }, [startDate]);

  useEffect(() => {
    if (!filters.start) setStartDate(null);
  }, [filters.start]);

  useEffect(() => {
    let queryObject = Object.assign({}, filters);
    if (endDate) queryObject.end = localeService.dateToString(endDate);
    else delete queryObject.end;

    history.push(`/?${QueryString.stringify(queryObject)}`);
  }, [endDate]);

  useEffect(() => {
    if (!filters.end) setEndDate(null);
  }, [filters.end]);

  const onCommentChange = e => setCommentValue(e.target.value.replace(/ {2,}$/g, " "));

  const onShowUsersChange = e => setShowAllUsers(e.target.checked);

  const hasStateSet = () => {
    if (filters == null) return false;
    if (filters.length > 0) return true;
    if (filters.length === 0) return false;
    if (typeof filters !== "object") return false;
    for (let key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) return true;
    }

    return false;
  };

  const customStyles = {
    control: base => ({
      ...base,
      boxShadow: "none",
      border: 0,
      fontSize: "0.9rem"
    })
  };

  const hasUserAccessToType = () => {
    const userValue = filters.user ? getUserValue(filters.user) : null;

    if (authorizedUser.role === "ADMIN") {
      if (!userValue) {
        return true;
      } else {
        return !!(userValue && userValue.role === "MANAGER");
      }
    } else if (authorizedUser.role === "MANAGER") {
      if (!authorizedUser.super) {
        return true;
      } else if (authorizedUser.super && !showUsers) {
        return true;
      } else {
        return !!(authorizedUser.super && showUsers && userValue && userValue.role === "MANAGER");
      }
    } else {
      return !!(authorizedUser.super && userValue && userValue.role === "MANAGER");
    }
  };

  const TypeOption = props => {
    const queryObject = Object.assign({}, filters, { type: props.value });
    if (props.value === "refill") {
      delete queryObject.department;
      delete queryObject.categories;
      delete queryObject.tag;
    }

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.Option {...props} />
      </NavLink>
    );
  };

  const TypeClearIndicator = props => {
    const queryObject = Object.assign({}, filters);
    delete queryObject.type;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.ClearIndicator {...props} />
      </NavLink>
    );
  };

  const DepartmentOption = props => {
    const queryObject = Object.assign({}, filters, { department: props.value });
    delete queryObject.categories;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.Option {...props} />
      </NavLink>
    );
  };

  const DepartmentClearIndicator = props => {
    const queryObject = Object.assign({}, filters);
    delete queryObject.department;
    delete queryObject.categories;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.ClearIndicator {...props} />
      </NavLink>
    );
  };

  const CategoryOption = props => {
    const queryObject = Object.assign({}, filters, {
      categories: filters.categories ? [...filters.categories, props.value] : [props.value]
    });

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.Option {...props} />
      </NavLink>
    );
  };

  const CategoryMultiValueRemove = props => {
    const queryObject = Object.assign({}, filters, {
      categories: filters.categories ? [...filters.categories.filter(cat => cat !== props.data.value)] : []
    });
    if (!queryObject.categories.length) delete queryObject.categories;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link d-flex">
        <components.MultiValueRemove {...props} />
      </NavLink>
    );
  };

  const CategoryClearIndicator = props => {
    const queryObject = Object.assign({}, filters);
    delete queryObject.categories;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.ClearIndicator {...props} />
      </NavLink>
    );
  };

  const TagOption = props => {
    const queryObject = Object.assign({}, filters, { tag: props.value });

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.Option {...props} />
      </NavLink>
    );
  };

  const TagClearIndicator = props => {
    const queryObject = Object.assign({}, filters);
    delete queryObject.tag;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.ClearIndicator {...props} />
      </NavLink>
    );
  };

  const getClearButtonForComment = () => {
    const queryObject = Object.assign({}, filters);
    delete queryObject.comment;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="form-clear">
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </NavLink>
    );
  };

  const UserOption = props => {
    const queryObject = Object.assign({}, filters, { user: props.value });
    if (props.data.role !== "MANAGER") delete queryObject.type;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.Option {...props} />
      </NavLink>
    );
  };

  const UserClearIndicator = props => {
    const queryObject = Object.assign({}, filters);
    delete queryObject.user;
    if (authorizedUser.role !== "ADMIN") delete queryObject.type;

    return (
      <NavLink to={`/?${QueryString.stringify(queryObject)}`} className="option-link">
        <components.ClearIndicator {...props} />
      </NavLink>
    );
  };

  const StartDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input">
        {value ? <span> {value}</span> : <span className="text-muted">Date</span>}
      </Button>
      {startDate ? (
        <span className="form-clear" onClick={() => setStartDate(null)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </span>
      ) : null}
    </div>
  ));

  const EndDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input">
        {value ? <span> {value}</span> : <span className="text-muted">Date</span>}
      </Button>
      {endDate ? (
        <span className="form-clear" onClick={() => setEndDate(null)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </span>
      ) : null}
    </div>
  ));

  return (
    <Form className="ExpensesFilters">
      <Form.Group>
        <small className="form-text text-muted pl-2">Start</small>
        <DatePicker
          minDate={minStartDate}
          maxDate={endDate || new Date()}
          locale={localeService.locale}
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={localeService.getLocaleDateString()}
          customInput={<StartDateInput />}
        />
      </Form.Group>

      <Form.Group>
        <small className="form-text text-muted pl-2">End</small>
        <DatePicker
          minDate={startDate || minStartDate}
          maxDate={new Date()}
          locale={localeService.locale}
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat={localeService.getLocaleDateString()}
          customInput={<EndDateInput />}
        />
      </Form.Group>

      <Form.Group>
        <small className="form-text text-muted pl-2">Department</small>
        <Select
          styles={customStyles}
          isClearable={true}
          isDisabled={filters.type && getTypeValue(filters.type) && getTypeValue(filters.type).value === "refill"}
          placeholder="All"
          components={{ Option: DepartmentOption, ClearIndicator: DepartmentClearIndicator }}
          value={filters.department ? getDepartmentValue(filters.department) : null}
          options={getDepartmentSelectOptions()}
        />
      </Form.Group>

      <Form.Group>
        <small className="form-text text-muted pl-2">Category</small>
        <Select
          styles={customStyles}
          isDisabled={!filters.department}
          isMulti
          placeholder="All"
          components={{
            Option: CategoryOption,
            MultiValueRemove: CategoryMultiValueRemove,
            ClearIndicator: CategoryClearIndicator
          }}
          value={filters.categories ? getCategoryValue(filters.categories) : null}
          options={getCategorySelectOptions()}
        />
      </Form.Group>

      <Form.Group>
        <small className="form-text text-muted pl-2">Tag</small>
        <Select
          styles={customStyles}
          isClearable={true}
          isDisabled={filters.type && getTypeValue(filters.type) && getTypeValue(filters.type).value === "refill"}
          placeholder="All"
          components={{ Option: TagOption, ClearIndicator: TagClearIndicator }}
          value={filters.tag ? getTagValue(filters.tag) : null}
          options={getTagSelectOptions()}
        />
      </Form.Group>

      <Form.Group className="position-relative">
        <small className="form-text text-muted pl-2">Comment</small>
        <Form.Control
          className="comment-search"
          value={commentValue}
          onChange={onCommentChange}
          maxLength={99}
          placeholder="All"
        />
        {commentValue ? getClearButtonForComment() : null}
      </Form.Group>

      {authorizedUser.super ? (
        <div className="show-users">
          <Form.Check
            inline
            custom
            checked={showUsers}
            type="checkbox"
            label="Show users"
            name="formShowUsers"
            id="show-users"
            onChange={onShowUsersChange}
          />
        </div>
      ) : null}

      {authorizedUser.role === "ADMIN" || showUsers ? (
        <Form.Group>
          <small className="form-text text-muted pl-2">User</small>
          <Select
            styles={customStyles}
            isClearable={true}
            placeholder="All"
            components={{ Option: UserOption, ClearIndicator: UserClearIndicator }}
            value={filters.user ? getUserValue(filters.user) : null}
            options={getUserSelectOptions()}
          />
        </Form.Group>
      ) : null}

      {hasUserAccessToType() ? (
        <Form.Group>
          <small className="form-text text-muted pl-2">Type</small>
          <Select
            styles={customStyles}
            isClearable={true}
            isSearchable={false}
            placeholder="All"
            components={{ Option: TypeOption, ClearIndicator: TypeClearIndicator }}
            value={filters.type ? getTypeValue(filters.type) : null}
            options={[
              { value: "expense", label: "Expense" },
              { value: "refill", label: "Refill" }
            ]}
          />
        </Form.Group>
      ) : null}

      {hasStateSet() ? (
        <Button variant="light" className="bg-white" as={NavLink} to="/">
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </Button>
      ) : null}
    </Form>
  );
};

export default ExpensesFilters;
