import React, { useState } from "react";
import { Button, ButtonToolbar, Card, Collapse, Fade, ListGroup } from "react-bootstrap";
import DepartmentEditor from "../DepartmentEditor/DepartmentEditor";
import DeleteConfirmation from "../../../DeleteConfirmation/DeleteConfirmation";

import { departmentService } from "../../../../services/departmentService";

const Department = ({
  dep,
  toggleDepHoverOn,
  toggleDepHoverOff,
  isSelectedDep,
  isHoveredDep,
  onSelect,
  onChange,
  onError
}) => {
  const [departmentEditorShow, setDepartmentEditorShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

  const deleteDepartment = depId => {
    setDeleteConfirmationShow(false);
    departmentService
      .deleteDepartment(depId)
      .then(onChange)
      .catch(onError);
  };

  return (
    <ListGroup.Item className="dep" onMouseEnter={() => toggleDepHoverOn(dep._id)} onMouseLeave={toggleDepHoverOff}>
      <div className="min d-flex ">
        <div className="label mr-5">
          <Button
            variant="light"
            disabled={!dep.categories.length}
            className={!dep.categories.length ? "disabled rounded-circle" : "rounded-circle"}
            onClick={() => onSelect(dep._id)}
          >
            {isSelectedDep(dep._id) && dep.categories.length ? (
              <i className="fa fa-angle-up" aria-hidden="true" />
            ) : (
              <i className="fa fa-angle-down" aria-hidden="true" />
            )}
          </Button>
          <span className="ml-1">{dep.name}</span>
        </div>
        <Fade in={isHoveredDep(dep._id)}>
          <div>
            <ButtonToolbar className="d-flex flex-nowrap">
              <Button variant="light" onClick={() => setDepartmentEditorShow(true)}>
                <i className="fa fa-pencil-square-o" aria-hidden="true" />
              </Button>
              <Button
                variant="light"
                disabled={!dep.accessToDeletion}
                className={!dep.accessToDeletion ? "disabled" : ""}
                onClick={() => setDeleteConfirmationShow(true)}
              >
                <i className="fa fa-trash-o" aria-hidden="true" />
              </Button>
              {departmentEditorShow ? (
                <DepartmentEditor
                  show={departmentEditorShow}
                  onHide={() => setDepartmentEditorShow(false)}
                  data={dep}
                  onAccept={onChange}
                />
              ) : null}
              {deleteConfirmationShow ? (
                <DeleteConfirmation
                  show={deleteConfirmationShow}
                  onHide={() => setDeleteConfirmationShow(false)}
                  onDelete={() => deleteDepartment(dep._id)}
                />
              ) : null}
            </ButtonToolbar>
          </div>
        </Fade>
      </div>
      <Fade in={isSelectedDep(dep._id)}>
        <div>
          <Collapse in={isSelectedDep(dep._id)}>
            <div className="max">
              <Card>
                {dep.categories.map(cat => (
                  <ListGroup.Item key={cat._id} className="cat d-flex align-items-center">
                    <div className="label mr-3">
                      <span className="ml-1">{cat.name}</span>
                    </div>
                  </ListGroup.Item>
                ))}
              </Card>
            </div>
          </Collapse>
        </div>
      </Fade>
    </ListGroup.Item>
  );
};

export default Department;
