const round = num => Math.round((+num + Number.EPSILON) * 100) / 100;

const toFloatStringWithSpaces = num => {
  const number = round(num).toFixed(2);
  const parts = number.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

export const moneyService = {
  round,
  toFloatStringWithSpaces
};
