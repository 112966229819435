import React, { useState, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal, Button, Form } from "react-bootstrap";
import AlertMessage from "../../../AlertMessage/AlertMessage";
import UnsavedChangesConfirmation from "../../../UnsavedChangesConfirmation/UnsavedChangesConfirmation";
import { departmentService } from "../../../../services/departmentService";

const DepartmentEditor = ({ show, onHide, data, onAccept }) => {
  const [unsavedChangesConfirmationShow, setUnsavedChangesConfirmationShow] = useState(false);

  const [name, setName] = useState(data ? data.name : "");
  const [inputFields, setInputFields] = useState(
    data
      ? data.categories.map(cat => ({ ...cat }))
      : [{ index: 0, name: "", accessToDeletion: true, key: new Date().getTime() }]
  );

  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const isNameEntered = () => {
    return name.trim() !== "";
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    if (data) {
      const categories = inputFields
        .map(inputField => ({
          _id: inputField._id ? inputField._id : "",
          index: inputField.index,
          name: inputField.name.trim()
        }))
        .filter(cat => cat.name !== "");

      departmentService
        .updateDepartment(data._id, { name: name.trim(), categories })
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(() => {
          setIsLoading(false);
          onError();
        });
    } else {
      const categories = inputFields
        .map(inputField => ({ index: inputField.index, name: inputField.name.trim() }))
        .filter(cat => cat.name !== "");

      departmentService
        .createDepartment({ name: name.trim(), categories })
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(() => {
          setIsLoading(false);
          onError();
        });
    }
  };

  const onNameChange = e => setName(e.target.value.trim() === "" ? e.target.value.trim() : e.target.value);

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].name = event.target.value.trim() === "" ? event.target.value.trim() : event.target.value;

    setInputFields(values);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ index: values.length, name: "", accessToDeletion: true, key: new Date().getTime() });
    setInputFields(values);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values.map((val, idx) => ({ ...val, index: idx })));
  };

  const isDataNotChanged = () => {
    if (data) {
      const currentCategories = inputFields
        .map(inputField => ({ name: inputField.name.trim() }))
        .filter(cat => cat.name !== "");

      return (
        name.trim() === data.name.trim() &&
        currentCategories.length === data.categories.length &&
        data.categories.every((cat, idx) => cat.name === currentCategories[idx].name)
      );
    } else return !isNameEntered();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedInputFields = reorder(inputFields, result.source.index, result.destination.index);

    setInputFields(reorderedInputFields.map((val, idx) => ({ ...val, index: idx })));
  }

  const getModalClass = confirmShow => (confirmShow ? "form-modal-shadow" : "");

  const onClose = () => {
    if (isDataNotChanged()) onHide();
    else setUnsavedChangesConfirmationShow(true);
  };

  const onDiscard = () => {
    setUnsavedChangesConfirmationShow(false);
    onHide();
  };

  return (
    <div>
      <div className="DepartmentEditor">
        <Modal
          className={getModalClass(unsavedChangesConfirmationShow)}
          show={show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" className="col-12 p-0 text-center">
              <span className="mr-n4">{(data ? "Update" : "Create") + " department"}</span>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </Modal.Title>
          </Modal.Header>
          <AlertMessage message={errorMessage} type="error" />
          <Modal.Body className="d-flex flex-column align-items-center">
            <div>
              <Form id="depEditorForm" className="d-flex flex-column align-items-start" onSubmit={onSubmit}>
                <div className="d-flex flex-nowrap mb-3 ml-4">
                  <Form.Control placeholder="Name" value={name} onChange={onNameChange} maxLength={30} required />
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="list">
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {inputFields.map((inputField, index) => (
                          <Fragment key={`${data && inputField._id ? inputField._id : inputField.key}`}>
                            <Draggable
                              draggableId={`${data && inputField._id ? inputField._id : inputField.key}`}
                              index={index}
                            >
                              {provided => (
                                <Form.Group
                                  className="d-flex flex-nowrap aline-items-center"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <small className="d-flex align-items-center mr-3 text-muted">
                                    <i className="fa fa-sort" aria-hidden="true" />
                                  </small>
                                  <Form.Control
                                    placeholder="Category"
                                    value={inputField.name}
                                    className="mr-1"
                                    onChange={event => handleInputChange(index, event)}
                                    maxLength={30}
                                    required
                                  />
                                  <Button
                                    variant="light"
                                    disabled={!inputField.accessToDeletion}
                                    className={!inputField.accessToDeletion ? "disabled" : ""}
                                    onClick={() => handleRemoveFields(index)}
                                  >
                                    <i className="fa fa-trash-o" aria-hidden="true" />
                                  </Button>
                                </Form.Group>
                              )}
                            </Draggable>
                          </Fragment>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Form>
              <Button
                variant="light"
                className="ml-4"
                disabled={inputFields.filter(inputField => inputField.name === "").length}
                onClick={() => handleAddFields()}
              >
                +<small> Category</small>
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {unsavedChangesConfirmationShow ? (
              <UnsavedChangesConfirmation
                show={unsavedChangesConfirmationShow}
                onHide={() => setUnsavedChangesConfirmationShow(false)}
                onDiscard={onDiscard}
              />
            ) : null}
            <Button
              type="submit"
              variant={data ? "primary" : "success"}
              form="depEditorForm"
              className="button-submit-editor"
              disabled={isLoading || (data ? isDataNotChanged() : false)}
            >
              {isLoading ? <i className="fa fa-spinner fa-pulse" aria-hidden="true" /> : data ? "Save" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DepartmentEditor;
