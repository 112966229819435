import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { localeService } from "../../../../services/localeService";

const ComparativeChartFilters = ({ authorizedUser, tags, minStartDate, onFiltersChange }) => {
  const initialState = {
    startDate: new Date(new Date().setMonth(0, 1)),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 1, 0))
  };

  const [startDate, setStartDate] = useState(initialState.startDate);
  const [endDate, setEndDate] = useState(initialState.endDate);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [tagValue, setTagValue] = useState(null);

  useEffect(() => {
    const department = departmentValue && !categoryValue ? departmentValue.value : null;
    const category = categoryValue ? categoryValue.value : null;
    const tag = tagValue ? tagValue.value : null;

    onFiltersChange({
      dates: [localeService.dateToString(startDate), localeService.dateToString(endDate)],
      department,
      category,
      tag
    });
  }, [startDate, endDate, departmentValue, categoryValue, tagValue]);

  useEffect(() => {
    setCategoryValue(null);
    if (departmentValue) setTagValue(null);
  }, [departmentValue]);

  useEffect(() => {
    if (tagValue) setDepartmentValue(null);
  }, [tagValue]);

  const onStartDateChange = date => setStartDate(new Date(new Date(date).setDate(1)));

  const onEndDateChange = date => setEndDate(new Date(new Date(date).setMonth(new Date(date).getMonth() + 1, 0)));

  const onDepartmentChange = (value, { action }) => {
    if (action === "clear") setDepartmentValue(null);
    else setDepartmentValue(value);
  };

  const onCategoryChange = (value, { action }) => {
    if (action === "clear") setCategoryValue(null);
    else setCategoryValue(value);
  };

  const onTagChange = (value, { action }) => {
    if (action === "clear") setTagValue(null);
    else setTagValue(value);
  };

  const hasStateSet = () => {
    return (
      localeService.dateToString(startDate) !== localeService.dateToString(initialState.startDate) ||
      localeService.dateToString(endDate) !== localeService.dateToString(initialState.endDate) ||
      departmentValue ||
      categoryValue ||
      tagValue
    );
  };

  const onButtonClick = () => {
    setStartDate(initialState.startDate);
    setEndDate(initialState.endDate);
    setDepartmentValue(null);
    setCategoryValue(null);
    setTagValue(null);
  };

  const getDepartmentSelectOptions = () => authorizedUser.departments.map(dep => ({ value: dep._id, label: dep.name }));

  const getCategorySelectOptions = () => {
    if (departmentValue !== null) {
      const selectedDepartment = authorizedUser.departments.find(dep => departmentValue.value === dep._id);
      return selectedDepartment.categories.map(cat => ({ value: cat._id, label: cat.name }));
    }
  };

  const getTagSelectOptions = () => tags.map(tag => ({ value: tag._id, label: tag.name }));

  const customStyles = {
    control: base => ({
      ...base,
      boxShadow: "none",
      borderColor: "hsl(0,0%,80%) !important",
      fontSize: "0.9rem"
    })
  };

  const StartDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input">
        {value}
      </Button>
      {localeService.dateToString(startDate) !== localeService.dateToString(initialState.startDate) ? (
        <span className="form-clear" onClick={() => setStartDate(initialState.startDate)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </span>
      ) : null}
    </div>
  ));

  const EndDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input">
        {value}
      </Button>
      {localeService.dateToString(endDate) !== localeService.dateToString(initialState.endDate) ? (
        <span className="form-clear" onClick={() => setEndDate(initialState.endDate)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </span>
      ) : null}
    </div>
  ));

  return (
    <Form className="ChartFilters">
      <Form.Group>
        <DatePicker
          minDate={minStartDate}
          maxDate={endDate}
          locale={localeService.locale}
          selected={startDate}
          onChange={onStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={localeService.getLocaleMonthYearString()}
          showMonthYearPicker
          customInput={<StartDateInput />}
        />
      </Form.Group>

      <Form.Group>
        <DatePicker
          minDate={startDate}
          maxDate={initialState.endDate}
          locale={localeService.locale}
          selected={endDate}
          onChange={onEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat={localeService.getLocaleMonthYearString()}
          showMonthYearPicker
          customInput={<EndDateInput />}
        />
      </Form.Group>

      <Form.Group>
        <Select
          styles={customStyles}
          isClearable={true}
          isDisabled={tagValue}
          placeholder="Department"
          value={departmentValue}
          onChange={onDepartmentChange}
          options={getDepartmentSelectOptions()}
          menuPortalTarget={document.querySelector("body")}
        />
      </Form.Group>

      <Form.Group>
        <Select
          styles={customStyles}
          isClearable={true}
          isDisabled={!departmentValue}
          placeholder="Category"
          value={categoryValue}
          onChange={onCategoryChange}
          options={getCategorySelectOptions()}
          menuPortalTarget={document.querySelector("body")}
        />
      </Form.Group>

      <Form.Group>
        <Select
          styles={customStyles}
          isClearable={true}
          isDisabled={departmentValue}
          placeholder="Tag"
          value={tagValue}
          onChange={onTagChange}
          options={getTagSelectOptions()}
          menuPortalTarget={document.querySelector("body")}
        />
      </Form.Group>

      {hasStateSet() ? (
        <Button variant="light" className="bg-white clear-button" onClick={onButtonClick}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </Button>
      ) : null}
    </Form>
  );
};

export default ComparativeChartFilters;
