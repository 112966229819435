import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import "./Login.scss";

import { authService } from "../../services/authService";
import { getMe, isLoggedInSelector } from "../../reducers";

const Login = ({ isLoggedIn, getMe }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onEmailChange = e => setEmail(e.target.value);
  const onPasswordChange = e => setPassword(e.target.value);

  const onSubmit = e => {
    e.preventDefault();

    authService
      .login(email, password)
      .then(getMe)
      .catch(error => setErrorMessage(error.message));
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <div className="Login">
      <div className="container">
        <form className="form-sign-in" onSubmit={onSubmit}>
          <Alert show={!!errorMessage} variant="warning">
            {errorMessage}
          </Alert>
          <p className="form-sign-in-heading">Please Login</p>
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={onEmailChange}
            maxLength={254}
            required
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={password}
            onChange={onPasswordChange}
            maxLength={50}
            required
          />
          <button className="btn btn-block btn-primary" type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedInSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMe: () => dispatch(getMe())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
