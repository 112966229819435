import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Modal, Button, InputGroup, Form, FormControl, Row, Col } from "react-bootstrap";
import AlertMessage from "../AlertMessage/AlertMessage";
import UnsavedChangesConfirmation from "../UnsavedChangesConfirmation/UnsavedChangesConfirmation";

import { expenseService } from "../../services/expenseService";
import { nbuService } from "../../services/nbuService";
import { moneyService } from "../../services/moneyService";
import { localeService } from "../../services/localeService";

const RefillEditor = ({ show, onHide, data, authorizedUser, editingDays, onAccept }) => {
  const [unsavedChangesConfirmationShow, setUnsavedChangesConfirmationShow] = useState(false);

  const initialState = {
    money: data ? data.money[0] : "",
    currency: data ? data.currency : authorizedUser.currency,
    comment: data ? data.comment : "",
    date: data && data._id ? new Date(data.date) : new Date(),
    dollars: data && data._id ? data.money[1] : ""
  };

  const [money, setMoney] = useState(initialState.money);
  const [currency] = useState(initialState.currency);
  const [comment, setComment] = useState(initialState.comment);
  const [date, setDate] = useState(initialState.date);

  const [exchangeRate, setExchangeRate] = useState(null);
  const [dollars, setDollars] = useState(initialState.dollars);

  const [errorType, setErrorType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const loadExchangeRate = () => {
    if (exchangeRate !== 0) setExchangeRate(null);

    nbuService
      .getExchangeRate(
        localeService
          .dateToString(date)
          .split("-")
          .join("")
      )
      .then(res => setExchangeRate(res.data[0].rate))
      .catch(onError);
  };

  useEffect(() => {
    if (currency === "UAH") loadExchangeRate();
    else setExchangeRate(null);
  }, [date, currency]);

  useEffect(() => {
    if (currency === "UAH" && exchangeRate === 0)
      onError("secondary", "NBU site unavailable. Please enter the amount of money in dollars manually.");
  }, [currency, exchangeRate]);

  const isDollarsNotChanged = () => {
    if (currency === "USD") return true;
    else {
      if (exchangeRate || typeof exchangeRate !== "number") return true;
      if (exchangeRate === 0)
        return Number(moneyService.round(dollars)) === Number(moneyService.round(initialState.dollars));
      return true;
    }
  };

  const isDataNotChanged = () => {
    return (
      Number(moneyService.round(money)) === Number(moneyService.round(initialState.money)) &&
      isDollarsNotChanged() &&
      currency === initialState.currency &&
      comment.trim() === initialState.comment &&
      date.toLocaleDateString() === initialState.date.toLocaleDateString()
    );
  };

  useEffect(() => {
    if ((!data && isDataNotChanged()) || (data && data._id && isDataNotChanged())) {
      setDollars(initialState.dollars);
    } else if (currency === "UAH") {
      if (exchangeRate) setDollars(moneyService.round(money / exchangeRate));
      else if (exchangeRate !== 0) setDollars("");
    } else {
      setDollars(moneyService.round(money));
    }
  }, [money, currency, exchangeRate, comment]);

  const onError = (type = "error", message = "Sorry, an error occurred!") => {
    setErrorType(type);
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{message}</span>
      </span>
    );
  };

  const getMinDayForEditing = () => {
    return authorizedUser.role === "ADMIN"
      ? new Date("1996-09-02")
      : new Date(new Date().getTime() - (editingDays - 1) * 24 * 60 * 60 * 1000);
  };

  const onMoneyChange = e => {
    if (!isNaN(e.target.value) && !e.target.value.includes("-")) setMoney(e.target.value);
  };
  const onDollarsChange = e => {
    if (!isNaN(e.target.value) && !e.target.value.includes("-")) setDollars(e.target.value);
  };
  const onCommentChange = e => setComment(e.target.value);

  const onDateChange = date => {
    if (date.getTime() > new Date().getTime()) {
      setDate(new Date());
    } else if (
      date.getTime() < new Date().getTime() - (editingDays - 1) * 24 * 60 * 60 * 1000 &&
      authorizedUser.role !== "ADMIN"
    ) {
      setDate(getMinDayForEditing());
    } else if (date.getTime() < new Date("1996-09-02").getTime() && authorizedUser.role === "ADMIN") {
      setDate(new Date("1996-09-02"));
    } else {
      setDate(date);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    const refill = { money: [moneyService.round(money), moneyService.round(dollars)], currency, comment, date };

    if (data && data._id) {
      expenseService
        .updateExpense(data._id, refill)
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(() => {
          setIsLoading(false);
          onError();
        });
    } else {
      expenseService
        .createExpense(refill)
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(() => {
          setIsLoading(false);
          onError();
        });
    }
  };

  const getCurrencyIcon = type => (type === "USD" ? "$" : "₴");

  const getModalClass = confirmShow => (confirmShow ? "form-modal-shadow" : "");

  const onClose = () => {
    if (isDataNotChanged()) onHide();
    else setUnsavedChangesConfirmationShow(true);
  };

  const onDiscard = () => {
    setUnsavedChangesConfirmationShow(false);
    onHide();
  };

  const getDaysForEditing = date => {
    if (data && data._id && data.author.role === "ADMIN") return "∞";
    else if ((!data && authorizedUser.role === "ADMIN") || (data && !data._id && authorizedUser.role === "ADMIN"))
      return "∞";
    else {
      const days = Math.ceil(
        (new Date(date).getTime() - (new Date().getTime() - editingDays * 24 * 60 * 60 * 1000)) / 24 / 60 / 60 / 1000
      );
      return days >= 0 ? days : 0;
    }
  };

  const getActionType = () => (data ? (data._id ? "Edit" : "Clone") : "Create ");

  const DateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} className="date-input-editor">
        <div>
          <span>{value}</span>
          <span>
            <i className="fa fa-calendar-o" aria-hidden="true" />
          </span>
        </div>
      </Button>
    </div>
  ));

  return (
    <div>
      <div className="RefillEditor">
        <Modal
          className={getModalClass(unsavedChangesConfirmationShow)}
          show={show}
          onHide={onHide}
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">{getActionType() + " refill"}</Modal.Title>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <AlertMessage message={errorMessage} type={errorType} />
          <Modal.Body>
            <Form id="refEditorForm" onSubmit={onSubmit}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Money
                </Form.Label>
                <InputGroup as={Col} sm="4">
                  <FormControl
                    value={money}
                    onChange={onMoneyChange}
                    onFocus={e => e.target.select()}
                    maxLength={10}
                    placeholder="Money"
                    required
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{getCurrencyIcon(currency)}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>

                {currency === "UAH" ? (
                  <Col className="d-flex align-items-center mw-100">
                    {exchangeRate === 0 ? (
                      <InputGroup>
                        <FormControl
                          value={dollars}
                          onChange={onDollarsChange}
                          onFocus={e => e.target.select()}
                          maxLength={10}
                          placeholder="Money"
                          required
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    ) : authorizedUser.role === "ADMIN" && exchangeRate ? (
                      <span className="text-muted">{moneyService.toFloatStringWithSpaces(dollars) + " $"}</span>
                    ) : authorizedUser.role === "ADMIN" && exchangeRate !== 0 ? (
                      <i className="fa fa-refresh fa-spin text-muted" aria-hidden="true" />
                    ) : null}
                  </Col>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  placeholder="Comment..."
                  rows="3"
                  value={comment}
                  onChange={onCommentChange}
                  maxLength={400}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="modal-footer d-flex justify-content-between">
            <div className="form-group d-flex align-items-center">
              <DatePicker
                form="refEditorForm"
                minDate={getMinDayForEditing()}
                maxDate={new Date()}
                locale={localeService.locale}
                selected={date}
                onChange={onDateChange}
                dateFormat={localeService.getLocaleDateString()}
                customInput={<DateInput />}
              />

              <div>
                {data && data._id && authorizedUser.role === "ADMIN" ? (
                  <div className="ml-4 d-flex">
                    <small className="text-muted">Author:</small>
                    <small className="text-muted ml-1 text-nowrap">{data.author.name}</small>
                  </div>
                ) : null}
                {(!data && authorizedUser.role === "ADMIN") ||
                (data && !data._id && authorizedUser.role === "ADMIN") ? null : (
                  <div className="ml-4 d-flex">
                    <small className="text-muted text-nowrap">
                      {data && data._id && authorizedUser.role === "ADMIN" && data.author._id !== authorizedUser._id
                        ? "Editing days for author:"
                        : "Days for editing:"}
                    </small>
                    <small className="text-muted ml-1">{getDaysForEditing(date)}</small>
                  </div>
                )}
              </div>
            </div>
            {unsavedChangesConfirmationShow ? (
              <UnsavedChangesConfirmation
                show={unsavedChangesConfirmationShow}
                onHide={() => setUnsavedChangesConfirmationShow(false)}
                onDiscard={onDiscard}
              />
            ) : null}
            <Button
              type="submit"
              variant={getActionType() === "Edit" ? "primary" : "success"}
              form="refEditorForm"
              className="button-submit-editor"
              disabled={
                isLoading ||
                (data && data._id
                  ? isDataNotChanged() || (typeof exchangeRate !== "number" && currency === "UAH")
                  : typeof exchangeRate !== "number" && currency === "UAH")
              }
            >
              {isLoading ? (
                <i className="fa fa-spinner fa-pulse" aria-hidden="true" />
              ) : getActionType() === "Edit" ? (
                "Save"
              ) : (
                "Report"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default RefillEditor;
