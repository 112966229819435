import axios from "axios";
import config from "../config";

const getStatsByDepartments = filters => {
  const query = `?dates=${filters.dates[0]}&dates=${filters.dates[1]}${
    filters.users ? "&users=" + filters.users.join("&users=") : ""
  }`;

  return axios.get(config.apiUrl + `/api/chart/pie${query}`);
};

const getComparativeStats = filters => {
  const query = `?dates=${filters.dates[0]}&dates=${filters.dates[1]}${
    filters.department ? "&department=" + filters.department : ""
  }${filters.category ? "&category=" + filters.category : ""}${filters.tag ? "&tag=" + filters.tag : ""}`;

  return axios.get(config.apiUrl + `/api/chart/bar${query}`);
};

const COLORS = [
  "#f23a01",
  "#fd7c01",
  "#04761f",
  "#006366",
  "#2e00a2",
  "#92004b",
  "#fd6301",
  "#7d8108",
  "#005341",
  "#1563bf",
  "#5c008c"
];

export const chartService = {
  getStatsByDepartments,
  getComparativeStats,
  COLORS
};
