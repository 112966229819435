import React, { useState, useEffect } from "react";
import * as QueryString from "query-string";
import { NavLink } from "react-router-dom";
import { PieChart, Pie, Sector, Cell } from "recharts";
import AlertMessage from "../../AlertMessage/AlertMessage";
import ProportionalChartFilters from "./ProportionalChartFilters/ProportionalChartFilters";
import DepartmentChart from "./DepartmentChart/DepartmentChart";
import "./ProportionalChart.scss";

import { chartService } from "../../../services/chartService";
import { moneyService } from "../../../services/moneyService";

const ProportionalChart = ({ getMe }) => {
  const [state, setState] = useState({ users: [], minStartDate: new Date("1996-09-02") });

  const [filters, setFilters] = useState(null);
  const onFiltersChange = data => setFilters(data);

  const [activeIndex, setActiveIndex] = useState(null);
  const onPieEnter = (data, index) => setActiveIndex(index);

  const [errorMessage, setErrorMessage] = useState("");

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const requestDepartmentsStats = filters => {
    chartService
      .getStatsByDepartments(filters)
      .then(({ data }) => {
        setState({
          departmentsStats: data.departmentsStats,
          minStartDate: !isNaN(Date.parse(data.minDate)) ? new Date(data.minDate) : new Date("1996-09-02"),
          users: data.users
        });
      })
      .then(getMe)
      .catch(onError);
  };

  useEffect(() => {
    if (filters) requestDepartmentsStats(filters);
  }, [filters]);

  useEffect(() => {
    if (state.departmentsStats && state.departmentsStats.reduce((acc, depStats) => acc + +depStats.money, 0)) {
      const index = state.departmentsStats
        .filter(depStats => depStats.money)
        .findIndex(depStats => +depStats.money > 0);
      setActiveIndex(index >= 0 ? index : null);
    } else setActiveIndex(null);
  }, [state]);

  const getSeparatingIndexOfString = string => {
    if (string.length < 24) return 0;
    const index = string.split("").reduce((acc, char, idx) => {
      if (char === " " && idx < 24) return idx;
      else return acc;
    }, 0);
    return index ? index : 23;
  };

  const getFirstPartOfString = string => {
    return string.slice(0, getSeparatingIndexOfString(string));
  };

  const getSecondPartOfString = string => {
    return string.slice(getSeparatingIndexOfString(string));
  };

  const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    return (
      <g>
        {getSeparatingIndexOfString(payload.name) ? (
          <>
            <text x={cx} y={cy} dy={-15} textAnchor="middle" fill={fill} className="small">
              {getFirstPartOfString(payload.name)}
            </text>
            <text x={cx} y={cy} textAnchor="middle" fill={fill} className="small">
              {getSecondPartOfString(payload.name)}
            </text>
          </>
        ) : (
          <text x={cx} y={cy} textAnchor="middle" fill={fill} className="small">
            {payload.name}
          </text>
        )}
        <text x={cx} y={cy} dy={25} textAnchor="middle">
          <tspan fill="#333"> {`${moneyService.toFloatStringWithSpaces(value)}`}</tspan>
          <tspan fill="#999">{` / ${Math.round(percent * 100) >= 1 ? Math.round(percent * 100) : "< 1"}%`}</tspan>
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const filterDepartmentsStatsToUnZeroData = depsStats =>
    depsStats.filter(depStats => depStats.money).sort((a, b) => b.money - a.money);

  return (
    <>
      <AlertMessage message={errorMessage} type="error" />
      <div className="ProportionalChart">
        <ProportionalChartFilters
          users={state.users}
          minStartDate={state.minStartDate}
          onFiltersChange={onFiltersChange}
        />
        {state.departmentsStats ? (
          <>
            <div className="main-container">
              {state.departmentsStats.reduce((acc, depStats) => acc + +depStats.money, 0) ? (
                <div className="chart-container">
                  <div className="pie-chart">
                    <span className="sum-money">
                      {moneyService.toFloatStringWithSpaces(
                        state.departmentsStats.reduce((acc, depStats) => acc + +depStats.money, 0)
                      ) + " $"}
                    </span>
                    <PieChart width={310} height={310}>
                      <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={filterDepartmentsStatsToUnZeroData(state.departmentsStats)}
                        cx={150}
                        cy={150}
                        innerRadius={90}
                        outerRadius={140}
                        startAngle={450}
                        endAngle={90}
                        dataKey="money"
                        minAngle={4}
                        paddingAngle={0}
                        onMouseEnter={onPieEnter}
                      >
                        {state.departmentsStats
                          .filter(depStats => depStats.money)
                          .map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={
                                chartService.COLORS[
                                  index > chartService.COLORS.length - 1 ? index % chartService.COLORS.length : index
                                ]
                              }
                            />
                          ))}
                      </Pie>
                    </PieChart>
                  </div>
                  <div className="chart-legend">
                    {filterDepartmentsStatsToUnZeroData(state.departmentsStats).map((depStats, i) => {
                      const percent =
                        depStats.money /
                        state.departmentsStats
                          .filter(depStats => depStats.money)
                          .reduce((acc, depStats) => acc + +depStats.money, 0);
                      const queryObject = {
                        start: filters.dates[0],
                        end: filters.dates[1],
                        showUsers: true,
                        department: depStats._id,
                        ...(filters.users && filters.users.length === 1 && { user: filters.users[0] })
                      };
                      return (
                        <NavLink
                          key={depStats + "_chart_" + i}
                          className="department-legend"
                          to={`/?${QueryString.stringify(queryObject)}`}
                          onMouseEnter={() => onPieEnter(null, i)}
                          target="_blank"
                        >
                          <div className="name">
                            <i
                              className="fa fa-circle"
                              style={{
                                color:
                                  chartService.COLORS[
                                    i > chartService.COLORS.length - 1 ? i % chartService.COLORS.length : i
                                  ],
                                opacity: activeIndex === i ? "1" : "0.5"
                              }}
                              aria-hidden="true"
                            />
                            {getSeparatingIndexOfString(depStats.name) ? (
                              <div>
                                <span className="mr-1">{getFirstPartOfString(depStats.name)}</span>
                                <span>{getSecondPartOfString(depStats.name)}</span>
                              </div>
                            ) : (
                              <span>{depStats.name}</span>
                            )}
                          </div>
                          <div className="numbers">
                            <span>{moneyService.toFloatStringWithSpaces(depStats.money)}</span>
                            <small className="text-muted">
                              {Math.round(percent * 100) >= 1 ? Math.round(percent * 100) : "< 1"}%
                            </small>
                          </div>
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="text-muted text-center">Nothing to compare</div>
              )}
            </div>
            <div className="departments-container">
              {filterDepartmentsStatsToUnZeroData(state.departmentsStats).map((depStats, i) => (
                <DepartmentChart department={depStats} color={8 + +i} key={depStats.name + i} filters={filters} />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ProportionalChart;
