import React, { useState } from "react";
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import ExpenseEditor from "../ExpenseEditor/ExpenseEditor";
import RefillEditor from "../RefillEditor/RefillEditor";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";

import { expenseService } from "../../services/expenseService";
import { moneyService } from "../../services/moneyService";

const Expense = ({
  authorizedUser,
  defaultTags,
  data,
  editingDays,
  selected,
  onSelectExpense,
  dollarSwitch,
  onChange,
  onError
}) => {
  const [expenseEditorShow, setExpenseEditorShow] = useState(false);
  const [refillEditorShow, setRefillEditorShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

  const [expenseEditorCloneShow, setExpenseEditorCloneShow] = useState(false);
  const [refillEditorCloneShow, setRefillEditorCloneShow] = useState(false);

  const deleteExpense = expId => {
    setDeleteConfirmationShow(false);
    expenseService
      .deleteExpense(expId)
      .then(onChange)
      .catch(onError);
  };

  const getCurrencyIcon = type => (type === "USD" ? "$" : "₴");

  const getDaysForEditing = date => {
    const days = Math.ceil(
      (new Date(date).getTime() - (new Date().getTime() - editingDays * 24 * 60 * 60 * 1000)) / 24 / 60 / 60 / 1000
    );
    return days >= 0 ? days : 0;
  };

  const setEditorShow = () => {
    if (data.category) setExpenseEditorShow(true);
    else setRefillEditorShow(true);
  };

  const renderCloneButton = () => {
    if (data.category) {
      if (
        (authorizedUser.role !== "MANAGER" ||
          (authorizedUser.role === "MANAGER" && authorizedUser.currency === data.currency)) &&
        authorizedUser.departments.find(dep => dep.categories.find(cat => cat._id === data.category._id))
      ) {
        return (
          <Button variant="light" onClick={() => setExpenseEditorCloneShow(true)}>
            <i className="fa fa-clone" aria-hidden="true" />
          </Button>
        );
      } else {
        return (
          <Button variant="light" disabled>
            <i className="fa fa-clone" aria-hidden="true" />
          </Button>
        );
      }
    } else if (authorizedUser.role === "MANAGER" && authorizedUser.currency === data.currency) {
      return (
        <Button variant="light" onClick={() => setRefillEditorCloneShow(true)}>
          <i className="fa fa-clone" aria-hidden="true" />
        </Button>
      );
    } else {
      return (
        <Button variant="light" disabled>
          <i className="fa fa-clone" aria-hidden="true" />
        </Button>
      );
    }
  };

  return (
    <>
      <tr className={selected ? "Expense selected" : "Expense"}>
        {authorizedUser.role === "ADMIN" ? (
          <td className="p-0">
            <div className="checkbox-expense d-flex align-items-center" onClick={onSelectExpense}>
              {selected ? (
                <i className="fa fa-check-square bigger text-dark font-weight-bold" aria-hidden="true" />
              ) : (
                <i className="fa fa-square-o smaller text-muted" aria-hidden="true" />
              )}
            </div>
          </td>
        ) : null}
        <td>
          {dollarSwitch
            ? moneyService.toFloatStringWithSpaces(data.money[1]) + " " + getCurrencyIcon("USD")
            : moneyService.toFloatStringWithSpaces(data.money[0]) + " " + getCurrencyIcon(data.currency)}
        </td>
        <td>{new Date(data.date).toLocaleDateString()}</td>
        <td>
          {data.category ? (
            <div className="d-flex flex-wrap">
              <span className="mr-1">{data.category.department.name}</span>
              <span className="text-muted">{data.category.name}</span>
            </div>
          ) : (
            <h4 className="text-success m-0">
              <i className="fa fa-money" aria-hidden="true" />
            </h4>
          )}
        </td>
        <td>
          {data.tags.length ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={data._id + "tags"}>
                  <div>
                    {data.tags.map(tag => (
                      <span key={data._id + tag._id} className="tag-label bg-dark rounded font-weight-bold text-nowrap">
                        {tag.name}
                      </span>
                    ))}
                  </div>
                </Tooltip>
              }
            >
              <i className={`fa ${data.tags.length > 1 ? "fa-tags" : "fa-tag"} text-muted p-2`} aria-hidden="true" />
            </OverlayTrigger>
          ) : null}
        </td>
        <td>
          {data.comment.split(/\s+/).length > 3 ? (
            <OverlayTrigger placement="top" overlay={<Tooltip id={data._id}>{data.comment}</Tooltip>}>
              <span className="text-muted">
                {data.comment
                  .split(/\s+/)
                  .slice(0, 3)
                  .join(" ") + "..."}
              </span>
            </OverlayTrigger>
          ) : (
            <span className="text-muted">{data.comment}</span>
          )}
        </td>
        {authorizedUser.role === "ADMIN" || authorizedUser.super ? <td>{data.author.name}</td> : null}
        <td>
          <ButtonToolbar className="d-flex flex-nowrap justify-content-end">
            {authorizedUser.role === "ADMIN" ||
            (authorizedUser._id === (data.author._id || data.author) && getDaysForEditing(data.date) > 0) ? (
              <>
                <Button variant="light" onClick={setEditorShow}>
                  <i className="fa fa-pencil-square-o" aria-hidden="true" />
                </Button>

                <Button variant="light" onClick={() => setDeleteConfirmationShow(true)}>
                  <i className="fa fa-trash-o" aria-hidden="true" />
                </Button>

                {expenseEditorShow ? (
                  <ExpenseEditor
                    show={expenseEditorShow}
                    onHide={() => setExpenseEditorShow(false)}
                    data={data}
                    authorizedUser={authorizedUser}
                    defaultTags={defaultTags}
                    editingDays={editingDays}
                    onAccept={onChange}
                  />
                ) : null}

                {refillEditorShow ? (
                  <RefillEditor
                    show={refillEditorShow}
                    onHide={() => setRefillEditorShow(false)}
                    data={data}
                    authorizedUser={authorizedUser}
                    editingDays={editingDays}
                    onAccept={onChange}
                  />
                ) : null}

                {deleteConfirmationShow ? (
                  <DeleteConfirmation
                    show={deleteConfirmationShow}
                    onHide={() => setDeleteConfirmationShow(false)}
                    onDelete={() => deleteExpense(data._id)}
                  />
                ) : null}
              </>
            ) : null}

            {renderCloneButton()}

            {expenseEditorCloneShow ? (
              <ExpenseEditor
                show={expenseEditorCloneShow}
                onHide={() => setExpenseEditorCloneShow(false)}
                data={{
                  money: data.money,
                  currency: data.currency,
                  category: data.category,
                  tags: data.tags,
                  comment: data.comment
                }}
                authorizedUser={authorizedUser}
                defaultTags={defaultTags}
                editingDays={editingDays}
                onAccept={onChange}
              />
            ) : null}

            {refillEditorCloneShow ? (
              <RefillEditor
                show={refillEditorCloneShow}
                onHide={() => setRefillEditorCloneShow(false)}
                data={{ money: data.money, currency: data.currency, comment: data.comment }}
                authorizedUser={authorizedUser}
                editingDays={editingDays}
                onAccept={onChange}
              />
            ) : null}
          </ButtonToolbar>
        </td>
      </tr>
    </>
  );
};

export default Expense;
