import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AlertMessage from "../../AlertMessage/AlertMessage";
import TagEditor from "./TagEditor/TagEditor";
import Tag from "./Tag/Tag";
import "./TagsEditor.scss";

import { tagService } from "../../../services/tagService";

const TagsEditor = ({ getMe }) => {
  const [tags, setTags] = useState(null);
  const [tagEditorShow, setTagEditorShow] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const requestTags = () => {
    tagService
      .getTags()
      .then(res => setTags(res.data))
      .then(getMe)
      .catch(onError);
  };

  useEffect(() => {
    requestTags();
  }, []);

  return tags ? (
    <div>
      <AlertMessage message={errorMessage} type="error" />
      <div className="TagsEditor">
        <div className="tags-header d-flex flex-column align-items-end">
          <Button variant="success" className="create-bnt" onClick={() => setTagEditorShow(true)}>
            New tag
          </Button>
          {tagEditorShow ? (
            <TagEditor show={tagEditorShow} onHide={() => setTagEditorShow(false)} onAccept={requestTags} />
          ) : null}
        </div>
        <div className="tags-body">
          {tags.map(tag => (
            <Tag key={tag._id} tag={tag} onChange={requestTags} onError={onError} />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default TagsEditor;
