import React, { useState, useEffect } from "react";
import * as QueryString from "query-string";
import { NavLink } from "react-router-dom";
import { PieChart, Pie, Sector, Cell } from "recharts";
import "./DepartmentChart.scss";

import { chartService } from "../../../../services/chartService";
import { moneyService } from "../../../../services/moneyService";

const DepartmentChart = ({ department, color, filters }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const onPieEnter = (data, index) => setActiveIndex(index);

  useEffect(() => {
    if (department.categories.reduce((acc, catStats) => acc + +catStats.money, 0)) {
      const index = department.categories.filter(catStats => catStats.money).findIndex(catStats => +catStats.money > 0);
      setActiveIndex(index >= 0 ? index : null);
    } else setActiveIndex(null);
  }, [department]);

  const getSeparatingIndexOfString = string => {
    if (string.length < 21) return 0;
    const index = string.split("").reduce((acc, char, idx) => {
      if (char === " " && idx < 21) return idx;
      else return acc;
    }, 0);
    return index ? index : 20;
  };

  const getFirstPartOfString = string => {
    return string.slice(0, getSeparatingIndexOfString(string));
  };

  const getSecondPartOfString = string => {
    return string.slice(getSeparatingIndexOfString(string));
  };

  const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const filterDepartmentStatsToUnZeroData = catStats =>
    catStats.filter(catStats => catStats.money).sort((a, b) => b.money - a.money);

  return (
    <div className="DepartmentChart">
      {department.categories.reduce((acc, catStats) => acc + +catStats.money, 0) ? (
        <div className="chart-container">
          <span className="department-name">{department.name}</span>
          <span className="sum-money">
            {moneyService.toFloatStringWithSpaces(
              department.categories.reduce((acc, catStats) => acc + +catStats.money, 0)
            ) + " $"}
          </span>
          <div className="pie-chart">
            <PieChart width={170} height={170}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={filterDepartmentStatsToUnZeroData(department.categories)}
                cx={80}
                cy={80}
                innerRadius={0}
                outerRadius={70}
                startAngle={450}
                endAngle={90}
                dataKey="money"
                minAngle={10}
                paddingAngle={0}
                onMouseEnter={onPieEnter}
              >
                {department.categories
                  .filter(catStats => catStats.money)
                  .map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        chartService.COLORS[
                          color + index > chartService.COLORS.length - 1
                            ? (color + index) % chartService.COLORS.length
                            : color + index
                        ]
                      }
                    />
                  ))}
              </Pie>
            </PieChart>
          </div>
          <div className="chart-legend">
            {filterDepartmentStatsToUnZeroData(department.categories).map((catStats, i) => {
              const percent =
                catStats.money /
                department.categories
                  .filter(catStats => catStats.money)
                  .reduce((acc, catStats) => acc + +catStats.money, 0);
              const queryObject = {
                start: filters.dates[0],
                end: filters.dates[1],
                showUsers: true,
                department: department._id,
                categories: catStats._id,
                ...(filters.users && filters.users.length === 1 && { user: filters.users[0] })
              };
              return (
                <NavLink
                  key={catStats + "_chart_" + i}
                  className="department-legend"
                  to={`/?${QueryString.stringify(queryObject)}`}
                  onMouseEnter={() => onPieEnter(null, i)}
                  target="_blank"
                >
                  <div className="name">
                    <i
                      className="fa fa-circle"
                      style={{
                        color:
                          chartService.COLORS[
                            color + i > chartService.COLORS.length - 1
                              ? (color + i) % chartService.COLORS.length
                              : color + i
                          ],
                        opacity: activeIndex === i ? "1" : "0.5"
                      }}
                      aria-hidden="true"
                    />
                    {getSeparatingIndexOfString(catStats.name) ? (
                      <div>
                        <span className="mr-1">{getFirstPartOfString(catStats.name)}</span>
                        <span>{getSecondPartOfString(catStats.name)}</span>
                      </div>
                    ) : (
                      <span>{catStats.name}</span>
                    )}
                  </div>
                  <div className="numbers">
                    <span>{moneyService.toFloatStringWithSpaces(catStats.money)}</span>
                    <small className="text-muted">
                      {Math.round(percent * 100) >= 1 ? Math.round(percent * 100) : "< 1"}%
                    </small>
                  </div>
                </NavLink>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="text-muted text-center">Nothing to compare</div>
      )}
    </div>
  );
};

export default DepartmentChart;
