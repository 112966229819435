import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteConfirmation = ({ show, onHide, onDelete }) => {
  return (
    <div>
      <div className="DeleteConfirmation">
        <Modal
          show={show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Confirm deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please confirm the deletion. After confirmation, it will be <strong>deleted permanently.</strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
