import axios from "axios";
import config from "../config";

import { authService } from "../services/authService";
import { removeAuthorizedUser, setHasAuthBeenCheckedToTrue } from "../reducers";

export default function checkRefreshToken(store) {
  let isRefreshing = false;
  let refreshSubscribers = [];

  function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
  }

  function onRefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
  }

  axios.interceptors.response.use(
    response => response,
    error => {
      const {
        config: conf,
        response: { status }
      } = error;
      const originalRequest = conf;

      if (status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios
            .post(config.apiUrl + "/api/auth/refresh_tokens", { refreshToken: authService.getCookie("refreshToken") })
            .then(response => {
              authService.setTokensInCookies(response);
              let newToken = response.data.token;
              isRefreshing = false;
              onRefreshed(newToken);
              refreshSubscribers = [];
            })
            .catch(() => {
              isRefreshing = false;
              refreshSubscribers = [];
              authService.removeCookies();
              store.dispatch(removeAuthorizedUser());
              store.dispatch(setHasAuthBeenCheckedToTrue());
            });
        }
        return new Promise(resolve => {
          subscribeTokenRefresh(token => {
            // replace the expired token and retry
            originalRequest.headers.Authorization = "Bearer " + token;
            resolve(axios(originalRequest));
          });
        });
      } else {
        return Promise.reject(error);
      }
    }
  );
}
