import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AlertMessage from "../../../AlertMessage/AlertMessage";
import UnsavedChangesConfirmation from "../../../UnsavedChangesConfirmation/UnsavedChangesConfirmation";

import { tagService } from "../../../../services/tagService";

const TagEditor = ({ show, onHide, data, onAccept }) => {
  const [unsavedChangesConfirmationShow, setUnsavedChangesConfirmationShow] = useState(false);

  const [name, setName] = useState(data ? data.name : "");
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onError = () =>
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{"Sorry, an error occurred!"}</span>
      </span>
    );

  const isNameEntered = () => {
    return name.trim() !== "";
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    const body = { name: name.trim() };

    if (data) {
      tagService
        .updateTag(data._id, body)
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(() => {
          setIsLoading(false);
          onError();
        });
    } else {
      tagService
        .createTag(body)
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(() => {
          setIsLoading(false);
          onError();
        });
    }
  };

  const onNameChange = e => setName(e.target.value.trim().length ? e.target.value : e.target.value.trim());

  const isDataNotChanged = () => {
    if (data) return name.trim() === data.name.trim();
    else return !isNameEntered();
  };

  const getModalClass = confirmShow => (confirmShow ? "form-modal-shadow" : "");

  const onClose = () => {
    if (isDataNotChanged()) onHide();
    else setUnsavedChangesConfirmationShow(true);
  };

  const onDiscard = () => {
    setUnsavedChangesConfirmationShow(false);
    onHide();
  };

  return (
    <div>
      <div className="TagEditor">
        <Modal
          className={getModalClass(unsavedChangesConfirmationShow)}
          show={show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" className="col-12 p-0 text-center">
              <span className="mr-n4">{(data ? "Update" : "Create") + " tag"}</span>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </Modal.Title>
          </Modal.Header>
          <AlertMessage message={errorMessage} type="error" />
          <Modal.Body className="d-flex flex-column align-items-center">
            <div>
              <Form id="tagEditorForm" className="d-flex flex-column align-items-start" onSubmit={onSubmit}>
                <div className="d-flex flex-nowrap mb-3 ml-4">
                  <Form.Control placeholder="Name" value={name} onChange={onNameChange} maxLength={30} required />
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {unsavedChangesConfirmationShow ? (
              <UnsavedChangesConfirmation
                show={unsavedChangesConfirmationShow}
                onHide={() => setUnsavedChangesConfirmationShow(false)}
                onDiscard={onDiscard}
              />
            ) : null}
            <Button
              type="submit"
              variant={data ? "primary" : "success"}
              form="tagEditorForm"
              className="button-submit-editor"
              disabled={isLoading || (data ? isDataNotChanged() : false)}
            >
              {isLoading ? <i className="fa fa-spinner fa-pulse" aria-hidden="true" /> : data ? "Save" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TagEditor;
