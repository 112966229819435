import axios from "axios";
import config from "../config";

const getUsers = () => axios.get(config.apiUrl + `/api/users/`);
const createUser = data => axios.post(config.apiUrl + `/api/user`, data);
const updateUser = (userId, data) => axios.put(config.apiUrl + `/api/user/${userId}`, data);
const deleteUser = userId => axios.delete(config.apiUrl + `/api/user/${userId}`);

export const userService = {
  getUsers,
  createUser,
  updateUser,
  deleteUser
};
