import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import RequiredSelect from "../../../RequiredSelect/RequiredSelect";
import AlertMessage from "../../../AlertMessage/AlertMessage";
import UnsavedChangesConfirmation from "../../../UnsavedChangesConfirmation/UnsavedChangesConfirmation";
import { userService } from "../../../../services/userService";

const UserEditor = ({ show, onHide, data, authorizedUser, onAccept }) => {
  const [unsavedChangesConfirmationShow, setUnsavedChangesConfirmationShow] = useState(false);

  const orderOptions = values => {
    return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
  };

  const initialState = {
    name: data ? data.name : "",
    email: data ? data.email : "",
    password: "",
    role: data ? data.role : "RECORDER",
    super: data ? data.super : false,
    currency: data ? data.currency : "USD",
    departments: data
      ? orderOptions(data.departments.map(dep => ({ value: dep._id, label: dep.name, isFixed: !dep.accessToDeletion })))
      : []
  };

  const [name, setName] = useState(initialState.name);
  const [email, setEmail] = useState(initialState.email);
  const [password, setPassword] = useState(initialState.password);
  const [role, setRole] = useState(initialState.role);
  const [superUser, setSuperUser] = useState(initialState.super);
  const [currency, setCurrency] = useState(initialState.currency);
  const [departments, setDepartments] = useState(initialState.departments);

  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!changePassword) setPassword("");
  }, [changePassword]);

  const onError = error => {
    let errorMessage = "";
    if (error && error.response && error.response.data && error.response.data.errors)
      for (const type in error.response.data.errors) {
        if (error.response.data.errors.hasOwnProperty(type) && error.response.data.errors[type].message)
          errorMessage += " " + error.response.data.errors[type].message + ".";
      }
    setErrorMessage(
      <span>
        <small className="mr-4">{new Date().toLocaleTimeString()}</small>
        <span>{errorMessage}</span>
      </span>
    );
  };

  const isDataNotChanged = () => {
    if (data) {
      return (
        name.trim() === initialState.name.trim() &&
        email === initialState.email &&
        password === initialState.password &&
        role === initialState.role &&
        Boolean(superUser) === Boolean(initialState.super) &&
        currency === initialState.currency &&
        departments.length === initialState.departments.length &&
        initialState.departments.map(dep => dep.value).every(dep => departments.map(dep => dep.value).includes(dep))
      );
    } else {
      return !(name || email || password || departments.length);
    }
  };

  const onNameChange = e => setName(e.target.value.trim().length ? e.target.value : e.target.value.trim());
  const onEmailChange = e => setEmail(e.target.value);
  const onPasswordChange = e => setPassword(e.target.value);
  const onRoleChange = e => setRole(e.target.value);
  const onSuperChange = e => setSuperUser(e.target.checked);
  const onCurrencyChange = e => setCurrency(e.target.value);

  const onDepartmentChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue && removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = departments.filter(v => v.isFixed);
        break;
      default:
        break;
    }

    if (value) {
      value = orderOptions(value);
      setDepartments(value);
    } else setDepartments([]);
  };

  const getDepartmentSelectOptions = () => authorizedUser.departments.map(dep => ({ value: dep._id, label: dep.name }));

  const onSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    const user = {
      name,
      email,
      role,
      super: superUser,
      currency,
      departments: departments.map(dep => dep.value)
    };

    if (data) {
      userService
        .updateUser(data._id, changePassword ? { ...user, password } : user)
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(error => {
          setIsLoading(false);
          onError(error);
        });
    } else {
      userService
        .createUser({ ...user, password })
        .then(() => {
          onAccept();
          onHide();
        })
        .catch(error => {
          setIsLoading(false);
          onError(error);
        });
    }
  };

  const isUserManager = () => data && data.role === "MANAGER";
  const isUserAdmin = () => role === "ADMIN";

  const getModalClass = confirmShow => (confirmShow ? "form-modal-shadow" : "");

  const onClose = () => {
    if (isDataNotChanged()) onHide();
    else setUnsavedChangesConfirmationShow(true);
  };

  const onDiscard = () => {
    setUnsavedChangesConfirmationShow(false);
    onHide();
  };

  return (
    <div>
      <div className="UserEditor">
        <Modal
          className={getModalClass(unsavedChangesConfirmationShow)}
          show={show}
          onHide={onHide}
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" className="col-12 p-0 text-center">
              <span className="mr-n4">{(data ? "Update" : "Create") + " user"}</span>
              <button type="button" className="close" onClick={onClose}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </Modal.Title>
          </Modal.Header>
          <AlertMessage message={errorMessage} type="error" />
          <Modal.Body className={data ? "m-0 mt-4" : "mt-4 mb-4"}>
            <Form id="userEditorForm" onSubmit={onSubmit}>
              <Form.Group as={Row}>
                <Form.Label as="legend" column xs={2} className="pt-0 pb-0 mw-100">
                  Role
                </Form.Label>
                <Col xs={6}>
                  <Form.Check
                    inline
                    custom
                    checked={role === "RECORDER"}
                    type="radio"
                    label="Recorder"
                    name="formHorizontalRole"
                    id="RECORDER"
                    value="RECORDER"
                    onChange={onRoleChange}
                    disabled={data}
                  />
                  <Form.Check
                    inline
                    custom
                    checked={role === "MANAGER"}
                    type="radio"
                    label="Manager"
                    name="formHorizontalRole"
                    id="MANAGER"
                    value="MANAGER"
                    onChange={onRoleChange}
                    disabled={data}
                  />
                  <Form.Check
                    inline
                    custom
                    checked={role === "ADMIN"}
                    type="radio"
                    label="Admin"
                    name="formHorizontalRole"
                    id="ADMIN"
                    value="ADMIN"
                    onChange={onRoleChange}
                    disabled={data}
                  />
                </Col>

                {isUserAdmin() ? null : (
                  <Col>
                    <Form.Check
                      inline
                      custom
                      checked={superUser}
                      type="checkbox"
                      label="super"
                      name="formSuper"
                      id="SUPER"
                      onChange={onSuperChange}
                    />
                  </Col>
                )}
              </Form.Group>

              <Form.Group as={Row} controlId="formGridName">
                <Form.Label column xs="2" className="mw-100">
                  Name
                </Form.Label>
                <Col>
                  <Form.Control value={name} onChange={onNameChange} maxLength={50} required placeholder="Name" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formGridEmail">
                <Form.Label column xs="2" className="mw-100">
                  Email
                </Form.Label>
                <Col>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={onEmailChange}
                    maxLength={254}
                    required
                    placeholder="Email"
                  />
                </Col>
              </Form.Group>

              {data ? null : (
                <Form.Group as={Row} controlId="formGridPassword">
                  <Form.Label column xs="2" className="mw-100">
                    Password
                  </Form.Label>
                  <InputGroup as={Col}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={onPasswordChange}
                      maxLength={50}
                      required
                      placeholder="Password"
                    />
                    <InputGroup.Append style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}>
                      <InputGroup.Text>
                        {showPassword ? (
                          <i className="fa fa-eye" aria-hidden="true" />
                        ) : (
                          <i className="fa fa-eye-slash" aria-hidden="true" />
                        )}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              )}

              {isUserAdmin() ? null : (
                <Form.Group as={Row} controlId="formGridDepartments" className="mb-4">
                  <Form.Label column xs="2" className="mw-100">
                    Departments
                  </Form.Label>
                  <Col>
                    <RequiredSelect
                      isMulti
                      placeholder="Departments"
                      value={departments}
                      onChange={onDepartmentChange}
                      options={getDepartmentSelectOptions()}
                      isClearable={departments.some(v => !v.isFixed)}
                      required
                    />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row}>
                <Form.Label as="legend" column xs={2} className="pt-0 pb-0 mw-100">
                  Currency
                </Form.Label>
                <Col>
                  <Form.Check
                    inline
                    custom
                    checked={currency === "USD"}
                    type="radio"
                    label="USD"
                    name="formHorizontalCurrency"
                    id="USD"
                    value="USD"
                    onChange={onCurrencyChange}
                    disabled={isUserManager()}
                  />
                  <Form.Check
                    inline
                    custom
                    checked={currency === "UAH"}
                    type="radio"
                    label="UAH"
                    name="formHorizontalCurrency"
                    id="UAH"
                    value="UAH"
                    onChange={onCurrencyChange}
                    disabled={isUserManager()}
                  />
                </Col>
              </Form.Group>

              {data ? (
                <Form.Group as={Row} className="mt-5">
                  <Col xs={3} className="mw-100 form-label col-form-label pr-0 d-flex flex-nowrap align-items-center">
                    <div className="d-flex flex-nowrap align-items-center">
                      <span
                        style={{ cursor: "default" }}
                        className={changePassword ? "mw-100 mr-2 text-nowrap" : "mw-100 mr-2 text-muted text-nowrap"}
                        onClick={() => setChangePassword(!changePassword)}
                      >
                        Change password
                      </span>
                      <Form.Check
                        type="switch"
                        id="password-switch"
                        checked={changePassword}
                        onChange={() => setChangePassword(!changePassword)}
                        label=""
                      />
                    </div>
                  </Col>
                  {changePassword ? (
                    <InputGroup as={Col}>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={onPasswordChange}
                        maxLength={50}
                        required
                        placeholder="Password"
                      />
                      <InputGroup.Append style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}>
                        <InputGroup.Text>
                          {showPassword ? (
                            <i className="fa fa-eye" aria-hidden="true" />
                          ) : (
                            <i className="fa fa-eye-slash" aria-hidden="true" />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  ) : null}
                </Form.Group>
              ) : null}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="form-group d-flex align-items-center">
              {unsavedChangesConfirmationShow ? (
                <UnsavedChangesConfirmation
                  show={unsavedChangesConfirmationShow}
                  onHide={() => setUnsavedChangesConfirmationShow(false)}
                  onDiscard={onDiscard}
                />
              ) : null}
              <Button
                type="submit"
                variant={data ? "primary" : "success"}
                form="userEditorForm"
                className="button-submit-editor"
                disabled={isLoading || (data ? isDataNotChanged() : false)}
              >
                {isLoading ? <i className="fa fa-spinner fa-pulse" aria-hidden="true" /> : data ? "Save" : "Create"}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UserEditor;
