import axios from "axios";

const getExchangeRate = date => {
  let maxTries = 2;

  const requestWithRetries = (resolve, reject) => {
    axios
      .get(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${date}&json`, {
        timeout: 10000 / (maxTries + 1 || 1)
      })
      .then(res => resolve(res))
      .catch(() => {
        if (maxTries < 1) {
          resolve({ data: [{ rate: 0 }] });
        } else {
          maxTries--;
          setTimeout(() => {
            requestWithRetries(resolve, reject);
          }, 300);
        }
      });
  };

  return new Promise(requestWithRetries);
};

export const nbuService = {
  getExchangeRate
};
